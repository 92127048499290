import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { accepterPratique, accepterTheorie, AjoutVisacim, candidat_API, refuserPratique, refuserTheorie } from "../../../../service/config";
import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";
import moment from "moment";

export default function DetailsExamCateg({ row }) {
  const [show, setshow] = useState(false);
  const [showw, setshoww] = useState(false);
  const { pathname } = useLocation();
  const [cand, setcand] = useState([]);
  const [load, setload] = useState(false);
  const [formValues, setFormValues] = useState({
    motif: "",
  });
  const handleShow = () => setshow(!show);
  const handleShoww = () => setshoww(!showw);
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };




  return (
    <div>
      <div className="d-flex align-items-center gap-3">
    <Button variant="primary" size="sm" className="rounded-3" onClick={() => handleShoww()}>
         Détails
        </Button>
        

      </div>
      <Modal centered scrollable show={showw} onHide={handleShoww}>
        <Modal.Header closeButton><h2 className="">Informations sur les Examens</h2></Modal.Header>
        <Modal.Body>
        <div className="">
      
      <table className="table table-striped table-bordered">
        <thead className="table-dark">
          <tr> 
            <th>Catégorie</th>
            {/* <th>Description</th> */}
            <th>Date de l'examen</th>
            <th>Heure de l'examen</th>
          </tr>
        </thead>
        <tbody>
          {row?.categories?.map((item, index) => (
            <tr key={index}>
              <td>{item?.affectettheories?.categorie?.categ}</td>
              {/* <td>{item?.affectettheories?.categorie?.desc_categ}</td> */}
              <td>
                {moment(item?.affectettheories?.date_exam).format("DD/MM/YYYY")}
              </td>
              <td>
                {moment(item?.affectettheories?.heurs_exam, "HH:mm:ss").format(
                  "HH:mm"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setshoww(false)} variant="secondary">
            Fermer
          </Button>
          
        </Modal.Footer>
      </Modal>
      
    </div>
  );
}
