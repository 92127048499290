import {
  faCheckCircle,
  faEdit,
  faEraser,
  faFilter,
  faFilterCircleXmark,
  faHouse,
  faPrint,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import {
  CATEGORIE_API,
  Examen_API,
  countexaminateur,
  examenspratiques_API,
  getCandidat,
  getCandidatoncim,
  getCandidatoncimall,
  getListExaminateur,
  getcentreByatt,
  listcandexampratique,
  organisme_API,
} from "../../../service/config";
import { toast } from "react-toastify";
import Select from "react-select";
import { regions } from "../../../service/auth/UtilApi";
import SelectCustom from "../../../outils/Select/SelectComponent";
import { useSelector } from "react-redux";
import SelectMultiCustomId from "../../../outils/Select/SelectMultiCustomId";
import { generateRandomString } from "../../../service/RandomString";
import SelectionExaminateur from "./Examinateur/SelectionExaminateur";
import SelectOneId from "../../../outils/Select/selectCustomId";
import isconnected from "../../../service/isconnected";
import moment from "moment";
import SelectFinalOne from "../../../outils/Select/SelectFinalOne";
import SelectFinal from "../../../outils/Select/SelectFinal";
import { XLSexport } from "../../../service/Print/XLSexport";
import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import DetailsCand from "./DetailsCand/DetailsCand";

export default function ValidationPratique() {
  const [choix, setchoix] = useState("");
  const abortController = new AbortController();
  const [edit,setedit] = useState(false)
  const handleedit = () => {
    setedit(!edit);
  };

  const [error, setError] = useState({
    categ: "",
  });
  const columns = [
    {
      name: "Détails",
      wrap: true,

      selector: (row) => <DetailsCand row={row} />,
      sortable: true,
    },
    {
      name: "Auto-école",
      width: "200px",
      wrap: true,
      selector: (row) => row.candidat.organisme.nom_org || "Attente mis a jour",
      sortable: true,
    },
    {
      name: "Nom et prenom",
      width: "170px",
      selector: (row) => (
        <>
          {row.candidat.nom_cand} {row.candidat.prenom_cand}
        </>
      ),
      sortable: true,
    },
    {
      name: "Catégorie examens",
      width: "170px",
      selector: (row) => permiscat(row.categorie),
      sortable: true,
    },
    {
      name: "Date et heure d'examen choisi",
      width: "200px",
      cell: (row) =>
        moment(row.affecterpratique[0].date_examp).locale("fr").format("LL") +
        " " +
        row.affecterpratique[0].heurs_examp,
      sortable: true,
    },
    {
      name: "catégorie à l'inscription",
      width: "170px",
      selector: (row) => row.candidat.categ_permis,
      sortable: true,
    },

    {
      name: "Visa CIM",
      width: "170px",
      selector: (row) => row.candidat.visa_cim || "non fourni",
      sortable: true,
    },
    // {
    //   name: "Validation CIM",
    //   minWidth: "280px",
    //   selector: (row) => (
    //     <span className="badge bg-cyan p-2">
    //       {row?.candidat?.status_examen_cand == "Affecter théorie"
    //         ? "Affecté théorie"
    //         : row?.candidat?.status_examen_cand}
    //     </span>
    //   ),
    //   sortable: true,
    // },

    {
      name: "Date d'inscription",
      selector: (row) =>
        moment(row.candidat.date_insc_cand).locale("fr").format("LL"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Date d'envoi",
      width: "200px",
      cell: (row) =>
        moment(row.candidat.date_status_cand).locale("fr").format("LL"),
      selector: (row) => row.date_status_cand,
      sortable: true,
    },

    {
      name: "nombre de session",
      width: "200px",
      selector: (row) => 0,
      sortable: true,
    },
    {
      name: "derogation",
      width: "200px",
      selector: (row) => "Aucun",
      sortable: true,
    },
    {
      name: "Adresse",
      width: "170px",
      selector: (row) => row.candidat.adresse_cand,
      sortable: true,
    },
    {
      name: "Ville",
      width: "200px",
      wrap: true,
      selector: (row) => row.candidat.ville_cand,
      sortable: true,
    },
    {
      width: "200px",
      name: "Region",
      wrap: true,
      selector: (row) => row.candidat.province_cand,
      sortable: true,
    },

    {
      name: "statut session",
      minWidth: "280px",
      selector: (row) => (
        <span className="badge bg-cyan p-2">
          {row.is_valid_exam ? "Validé pour un examen " : "Attente validation"}
        </span>
      ),
      sortable: true,
    },
  ];
  const columns_valid = [
    {
      name: "Détails",
      wrap: true,

      selector: (row) => <DetailsCand row={row} />,
      sortable: true,
    },
    {
      name: "Auto-école",
      width: "200px",
      wrap: true,
      selector: (row) => row.candidat.organisme.nom_org || "Attente mis a jour",
      sortable: true,
    },
    {
      name: "Nom et prenom",
      width: "170px",
      selector: (row) => (
        <>
          {row.candidat.nom_cand} {row.candidat.prenom_cand}
        </>
      ),
      sortable: true,
    },
    {
      name: "Catégorie examens",
      width: "170px",
      selector: (row) => permiscat(row.categorie),
      sortable: true,
    },
    {
      name: "Date et heure d'examen choisi",
      width: "200px",
      cell: (row) =>
        moment(row.affecterpratique[0].date_examp).locale("fr").format("LL") +
        " " +
        row.affecterpratique[0].heurs_examp,
      sortable: true,
    },
    {
      name: "catégorie à l'inscription",
      width: "170px",
      selector: (row) => row.candidat.categ_permis,
      sortable: true,
    },

    {
      name: "Visa CIM",
      width: "170px",
      selector: (row) => row.candidat.visa_cim || "non fourni",
      sortable: true,
    },
    // {
    //   name: "Validation CIM",
    //   minWidth: "280px",
    //   selector: (row) => (
    //     <span className="badge bg-cyan p-2">
    //       {row?.candidat?.status_examen_cand == "Affecter théorie"
    //         ? "Affecté théorie"
    //         : row?.candidat?.status_examen_cand}
    //     </span>
    //   ),
    //   sortable: true,
    // },

    {
      name: "Date d'inscription",
      selector: (row) =>
        moment(row.candidat.date_insc_cand).locale("fr").format("LL"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Date d'envoi",
      width: "200px",
      cell: (row) =>
        moment(row.candidat.date_status_cand).locale("fr").format("LL"),
      selector: (row) => row.date_status_cand,
      sortable: true,
    },

    {
      name: "nombre de session",
      width: "200px",
      selector: (row) => 0,
      sortable: true,
    },
    {
      name: "derogation",
      width: "200px",
      selector: (row) => "Aucun",
      sortable: true,
    },
    {
      name: "Adresse",
      width: "170px",
      selector: (row) => row.candidat.adresse_cand,
      sortable: true,
    },
    {
      name: "Ville",
      width: "200px",
      wrap: true,
      selector: (row) => row.candidat.ville_cand,
      sortable: true,
    },
    {
      width: "200px",
      name: "Region",
      wrap: true,
      selector: (row) => row.candidat.province_cand,
      sortable: true,
    },

    {
      name: "statut session",
      minWidth: "280px",
      selector: (row) => (
        <span className="badge bg-cyan p-2">
          {row.is_valid_exam ? "Validé pour un examen " : "Attente validation"}
        </span>
      ),
      sortable: true,
    },
  ];

  const permis = (perm) => {
    const filteredData = categ.filter((item) => item.value === perm);

    if (perm) {
      return (
        <div className="d-flex gap-1 flex-wrap">
          <span className="badge bg-cyan">{filteredData[0]?.label}</span>
        </div>
      );
    } else {
      return "Aucun permis !";
    }
  };

  const permiscat = (perm) => {
    const filteredData = perm;

    if (perm) {
      return (
        <div className="d-flex gap-1 flex-wrap">
          {filteredData?.map((el, i) =>
            el.categ ? (
              <span key={i} className="badge bg-cyan">
                {el.categ}
              </span>
            ) : null
          )}
        </div>
      );
    } else {
      return "Aucun permis !";
    }
  };
  const [selectExam, setselectExam] = useState({
    nom_memb: "",
    prenom_memb: "",
  });
  const [eventy, seteventy] = useState("");
  const [data, setdata] = useState([]);
  const [autoecole, setautoecole] = useState([]);
  const [centre, setcentre] = useState([]);
  const [Examinateur, setExaminateur] = useState([]);
  const [loadExamin, setloadexamin] = useState(false);
  const [filtre, setfiltre] = useState({
    categorie: "",
    auto: "",
    dateinsc: "",
  });
  const [load, setLoad] = useState(false);
  const [loadValid, setLoadValid] = useState(false);
  const [candidatSelect, setcandidatSelect] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const categ = useSelector((state) => state.categ);
  const Alltype = useSelector((state) => state.listtypeexam);

  const getExaminateur = (id) => {
    setloadexamin(true);
    axios
      .get(getListExaminateur + id, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep);
        setExaminateur(rep.data.data);
        setloadexamin(false);
      });
  };
  useEffect(() => {
    getExaminateur(filtre?.centre);
  }, [filtre?.centre]);
  const getAutoecole = () => {
    let auto = [];
    try {
      axios
        .get(organisme_API + "?type_org=Auto-écoles", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            auto.push({ label: element.nom_org, value: element.id_org });
          }
          setautoecole(auto);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste auto-école");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };
  const getcentre = () => {
    let cent = [];
    try {
      axios
        .get(getcentreByatt + "Examinateurs", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            cent.push({ label: element.nom_org, value: element.id_org });
          }
          setcentre(cent);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste centre");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };
  const [showvalid, setshowvalid] = useState(false);
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };
  const handleValidation = () => setshowvalid(!showvalid);
  useEffect(() => {
    getAutoecole();
    getcentre();
    return () => {
      abortController.abort();
    };
  }, []);

  const filtredCandidat = async () => {
    console.log("mande");
    setLoad(true);
    try {
      await axios
        .get(listcandexampratique, {
          params: {
            id_categ: filtre?.categ?.slice(0, -1) || null,
            region: filtre?.region || null,
            id_org: filtre.auto || null,
            date_debut: filtre?.date_debut || null,
            date_fin: filtre?.date_fin || null,
          },
        })
        .then((reponse) => {
          console.log(reponse);
          setdata(reponse.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  const validerCand = () => {
    setLoadValid(true);
    try {
      axios
        .post(examenspratiques_API, {
          id_org: filtre?.centre,
          date_examp: filtre?.date_exam,
          heurs_examp: filtre?.heurs_exam,
          id_typeexam: filtre?.id_categ,
          id_memb: selectExam?.id_memb,
          candidats: candidatSelect,
          // id_categ: filtre.id_categ,
        })
        .then((reponse) => {
          setLoad(false);
          console.log(reponse);
          toast.success("candidats valider !");
          setLoadValid(false);
          handleValidation();
          settoggledClearRows(!toggledClearRows);
          setcandidatSelect([]);
          filtredCandidat();
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        })
        .catch((err) => {
          setLoad(false);
          toast.error("un erreur est arrivée");
          handleValidation();
          console.log(err);
          setLoadValid(false);
        });
    } catch (error) {
      setLoadValid(false);
      console.log(error);
      handleValidation();
    }
  };

  const handleFiltre = (e) => {
    const { name, value } = e.target;

    if (name == "date") {
      const deb = value
        ? moment(new Date(value[0])).format("YYYY-MM-DD")
        : null;
      const fin = value
        ? moment(new Date(value[1])).format("YYYY-MM-DD")
        : null;
      setfiltre({
        ...filtre,
        date_debut: deb,
        date_fin: fin,
        date: value,
      });
    } else {
      setfiltre({ ...filtre, [name]: value });
    }
  };

  useEffect(() => {
    setselectExam({});
  }, [filtre.centre]);

  useEffect(() => {
    console.log(filtre);
  }, [filtre]);

  useEffect(() => {
    filtredCandidat();
  }, [eventy, filtre?.auto, filtre?.date_debut, filtre?.date_fin]);

  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={showvalid}
        onHide={handleValidation}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Validation de liste des candidats selectionnés à l'examen </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label>
        <input 
          type="checkbox" 
          checked={!edit} 
          onChange={handleedit} 
        />
        {" "}decochez ici si une modification de date et heure d'examen est requise*
      </label>
          <div className="row">
            <div className="col-4">
              {" "}
              <div className="my-3">
                <label className="form-label">Date d'examen</label>
                <input
                  className={"form-control datepicker"}
                  disabled={!edit}
                  value={filtre?.date_exam}
                  onChange={(e) =>
                    setfiltre({ ...filtre, date_exam: e.target.value })
                  }
                  type="date"
                />
              </div>
            </div>
            <div className="col-4">
              {" "}
              <div className="my-3">
                <label className="form-label">Heure d'examen</label>
                <input
                  className="form-control timepicker"
                  value={filtre?.heurs_exam}
                  disabled={!edit}
                  onChange={(e) =>
                    setfiltre({ ...filtre, heurs_exam: e.target.value })
                  }
                  type="time"
                />
              </div>
            </div>
            <div className="col-4">
              {" "}
              <div className="my-3">
                <label className="form-label">Centre d'examen</label>
                <Select
                  options={centre}
                  onChange={(select) =>
                    setfiltre({ ...filtre, centre: select.value })
                  }
                  className="w-100"
                  placeholder="Sélectionnez centre..."
                />
              </div>
            </div>
            <div className="col-12">
              <DataTable
                className="custom-table"
                title={
                  <div className="d-flex justify-content-between">
                    <p className="fs-2">Liste des candidats</p>
                    <div>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() =>
                          XLSexport(data, "Les candidats", "Liste")
                        }
                      >
                        <FontAwesomeIcon icon={faPrint} className="me-2" />
                        Export Excel
                      </Button>
                    </div>
                  </div>
                }
                columns={columns_valid}
                data={candidatSelect
                  .sort(
                    (a, b) =>
                      new Date(b.date_status_cand) -
                      new Date(a.date_status_cand)
                  )
                  .filter(
                    (el, i) =>
                      el.candidat.status_examen_cand !==
                      "Candidat apte à l'examen pratique"
                  )}
                contextActions={
                  <button
                    className="btn btn-cyan me-2"
                    onClick={() => {
                      // ValiderCandidat();
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    <strong></strong>
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loadValid}
            onClick={handleValidation}
          >
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={loadValid}
            onClick={() =>  {
              if (!filtre?.centre ||candidatSelect.length == 0) {
                toast.info(
                  "veuillez choisir les champs centre avant de valider !"
                );
              } else {
                validerCand()
  
              }
            }
          }
          >
            <>Valider</>
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body pb-4">
              <div className="">
                <h1 className="text-start">Examens Pratique </h1>
                <h4>Gestion des examens et validation des candidats</h4>
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-body">
              <div className="">
                <p className="fs-2">Filtre : </p>
                <div className="row">
                  <div className="col-4">
                    <div className="my-2">
                      <label className="form-label">Auto-école</label>
                      <SelectFinalOne
                        options={autoecole}
                        load={false}
                        value={"value"}
                        label={"label"}
                        choix={choix}
                        placeholder="choix auto-école"
                        setFormValues={setfiltre}
                        formValues={filtre}
                        setError={setError}
                        error={error}
                        name={"auto"}
                        seteventy={seteventy}
                      />
                    </div>
                    {/* <div className="my-2">
                    <label className="form-label">Auto-école</label>
                    <Select
                      options={autoecole}
                      onChange={(select) => {
                        setfiltre({ ...filtre, auto: select.value });
                        seteventy(generateRandomString(5));
                      }}
                      className="w-100"
                      placeholder="Sélectionnez Auto-école..."
                      isClearable
                    />
                  </div> */}
                  </div>
                  {/* <div className="col-4">
                  <div className="my-2">
                    <label className="form-label">Région</label>
                    <SelectFinalOne
                      options={regions}
                      load={false}
                      value={"value"}
                      label={"label"}
                      choix={choix}
                      placeholder="Sélectionnez région..."
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"region"}
                      seteventy={seteventy}
                    />
                  
                  </div>
                </div> */}
                  <div className="col-4">
                    <div className="my-2">
                      <label className="form-label">Catégorie permis</label>
                      <SelectFinal
                        options={categ}
                        load={false}
                        value={"value"}
                        label={"label"}
                        choix={choix}
                        placeholder="choix catégorie"
                        setFormValues={setfiltre}
                        formValues={filtre}
                        setError={setError}
                        error={error}
                        name={"id_categ"}
                        seteventy={seteventy}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="my-2">
                      <label htmlFor="" className="form-label">
                        Date d'examen :(choisir entre deux dates)
                      </label>
                      <Calendar
                        className="w-100 h-100"
                        style={{ height: "35px" }}
                        locale={"fr"}
                        value={filtre?.date}
                        name="date"
                        onChange={handleFiltre}
                        selectionMode="range"
                        showButtonBar
                        showIcon
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  className="custom-table"
                  title={
                    <>
                      <div className="d-flex py-4 justify-content-between">
                        <div>
                          <h2>Sélection des candidats pour validation</h2>
                        </div>
                        <div>
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() =>
                              XLSexport(data, "Les candidats", "Liste")
                            }
                          >
                            <FontAwesomeIcon icon={faPrint} className="me-2" />
                            Export Excel
                          </Button>
                        </div>
                      </div>
                    </>
                  }
                  columns={columns}
                  data={data
                    .sort(
                      (a, b) =>
                        new Date(b.candidat.date_status_cand) -
                        new Date(a.candidat.date_status_cand)
                    )
                    .filter(
                      (el, i) =>
                        el?.candidat?.status_examen_cand !==
                        "Candidat apte à l'examen pratique"
                    )}
                  responsive
                  theme="material"
                  selectableRows
                  clearSelectedRows={toggledClearRows}
                  onSelectedRowsChange={(rows) => {
                    setSelectedRows(
                      rows.selectedRows.map((row) => row.id_cand)
                    );
                    setcandidatSelect(rows.selectedRows.map((row) => row));
                  }}
                  progressPending={load}
                  selectedRow={selectedRows}
                  contextActions={
                    <div className="row align-items-center g-3">
                      <div className="col-3">
                        <Button
                          className="me-2"
                          variant="secondary"
                          onClick={() => {
                            settoggledClearRows(!toggledClearRows);
                            setcandidatSelect([]);
                          }}
                        >
                          <FontAwesomeIcon icon={faUndoAlt} className="" />
                        </Button>
                      </div>
                      <div className="col-6">
                            <div className="">
                              <button
                                className={
                                  "btn btn-success px-4 w-100 " +
                                  (loadValid && "disabled")
                                }
                                onClick={() => {
                                 
                                    handleValidation();
                                  
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="mx-2"
                                />
                                Valider
                              </button>
                            </div>
                          </div>
                    </div>
                  }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
