import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHouseLock,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { organisme_API } from "../../../service/config";
import { toast } from "react-toastify";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default function ChangerStatusAutoEcole(props) {
  const [statusModif, setStatusModif] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [load, setload] = useState(false);
  const changerStatus = async (row, status) => {
    try {
      setload(true);
      await axios
        .put(organisme_API + "/" + row.id_org, {
          active_org: status,
          contenue_notif: formValues.contenue_notif,
        })
        .then((reponse) => {
          toast.success("Status mis a jour !");

          setload(false);

          setStatusModif(!statusModif);
          props.setrecharger(Math.floor(Math.random() * 11));
        })
        .catch((error) => {
          console.log(error);
          props.setrecharger(Math.floor(Math.random() * 11));
          setStatusModif(!statusModif);
          setload(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Tag
        onClick={() => setStatusModif(true)}
        severity={
          props.row.active_org === "Activer"
            ? "success"
            : props.row.active_org === "Desactiver"
            ? "secondary"
            : "danger"
        }
        value={
          props.row.active_org == "Activer"
            ? "Activé"
            : props.row.active_org == "Desactiver"
            ? "Désactivé"
            : "Bloqué"
        }
      ></Tag>
      <Modal
        show={statusModif}
        onHide={() => {
          setStatusModif(!statusModif);
          setload(false);
        }}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Status</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="mb-3">
              <label className="form-label">Notification</label>

              <CKEditor
              
                      
                      editor={Editor}
                      data={""}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                        // editor.enableReadOnlyMode("editor")
                        // const toolbar = editor.ui.view.toolbar.element;
                        // toolbar.style.display ='none'
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log("ato");
                        // console.log( { event, editor, data } );
                        console.log(data);
                    
                          setFormValues({ ...formValues, contenue_notif: data });
                          
                        
                      }}
                    />
              
            </div>

          <div className="d-flex justify-content-center w-100 gap-3">
            {props.row.active_org !== "Activer" && (
              <Button
                className={" " + (load && "disabled")}
                variant="success"
                onClick={() => changerStatus(props.row, "Activer")}
              >
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                Activé
              </Button>
            )}
            {props.row.active_org !== "Desactiver" && (
              <Button
                className={" " + (load && "disabled")}
                variant="secondary"
                onClick={() => changerStatus(props.row, "Desactiver")}
              >
                <FontAwesomeIcon icon={faUnlink} className="me-2" /> Désactivé
              </Button>
            )}{" "}
            {props.row.active_org !== "Bloquer" && (
              <Button
                className={" " + (load && "disabled")}
                variant="danger"
                onClick={() => changerStatus(props.row, "Bloquer")}
              >
                <FontAwesomeIcon icon={faHouseLock} className="me-2" /> Bloquer
              </Button>
            )}
          
          </div>
          
        </Modal.Body>

      
      </Modal>
    </>
  );
}
