import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import image from "../../assets/image/imgtest/image";
import { notificationCli_API } from "../../service/config";
import Notif from "../../service/context/NotifContext";


 function BoiteNotification(props) {
  const [listnotif, setlistnotif] = useState([]);
  const [Afficher,setAfficher] = useState(false)
  const abortController = new AbortController();
  moment.locale("fr", {
    months:
      "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
    monthsShort:
      "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
    monthsParseExact: true,
    weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
    weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
    weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
    calendar: {
      sameDay: "[Aujourd’hui à] LT",
      nextDay: "[Demain à] LT",
      nextWeek: "dddd [à] LT",
      lastDay: "[Hier à] LT",
      lastWeek: "dddd [dernier à] LT",
      sameElse: "L",
    },
    relativeTime: {
      future: "dans %s",
      past: "il y a %s",
      s: "quelques secondes",
      m: "une minute",
      mm: "%d minutes",
      h: "une heure",
      hh: "%d heures",
      d: "un jour",
      dd: "%d jours",
      M: "un mois",
      MM: "%d mois",
      y: "un an",
      yy: "%d ans",
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
      return number + (number === 1 ? "er" : "e");
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
      return input.charAt(0) === "M";
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? "PD" : "MD";
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  });
  const {notif,setnotif} = useContext(Notif);
  const [load, setload] = useState(true);
  const [nombrmes, setnombrmes] = useState(0);
  const getAllNotification = async () => {
   setload(true);
    try {
      axios.get(notificationCli_API,{
        signal: abortController.signal,
        }).then((list) => {
        console.log(list);
        setload(false);
        setlistnotif(list.data.data);
        let i=0
        for (let index = 0; index < list.data.data.length; index++) {
          const element = list.data.data[index];
          if (element.is_read === false) {
            i++;
          }
        }
        setnombrmes(i);
        setTimeout(() => {
          setAfficher(true)
          getAllNotification()
        }, 5000);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updatenotif = async (id_c) => {
    try {
      axios.get(notificationCli_API+'/'+id_c,{
        signal: abortController.signal,
        }).then((list) => {
        console.log(list);
      }).then(()=>  {
        setTimeout(() => {
          axios.get(notificationCli_API,{
            signal: abortController.signal,
            }).then((list) => {
       
            let i=0
            for (let index = 0; index < list.data.data.length; index++) {
              const element = list.data.data[index];
              if (element.is_read === false) {
                i++;
              }
            }
            setnombrmes(i);
    
          })
        }, 500);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const Notifcomponent = ()=> {
    return  <h1> Boite de notification <span className="bg-cyan text-light rounded-3 mb-2 px-1 fs-5">{nombrmes}</span></h1>
  }
  useEffect(() => {
    getAllNotification();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
   Notifcomponent()
  }, [nombrmes])
  

  return (
    <div className="container-fluid">
      <div className="container py-3">
        <div className="d-flex justify-content-between">
         <Notifcomponent/>
          {/* <div className="d-flex">
            <select className="form-control rounded-3">
              <option value="">Limite</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div> */}
        </div>{" "}
        <div className="row mb-5 mt-2" style={{maxHeight:'450px',overflowY:'auto'}}>
          {load==true && Afficher==false  ? (
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : listnotif.length === 0 ? (
            <div className="w-100 text-center py-5 ">
              <p className="display-5">Aucune notification à afficher !</p>
            </div>
          ) : (
            listnotif.map((el, index) => (
              <div
              id="faq-3"
              className="accordion my-1 col-12"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="accordion-item" style={{backgroundColor:`${el.is_read==false ? '#f1f5f9' : ''}`}}>
                <div className="accordion-header" role="tab">
                  <button
                    className="accordion-button collapsed"
                    onClick={()=>{
                      if (el.is_read===false) {
                        updatenotif(el.id_notif)
                      }}}
                    data-bs-toggle="collapse"
                    data-bs-target={`#faq${index}`}
                    aria-expanded="false"
                  >
                  <span className="h2 m-0 p-0"> {el.motif_notif}</span>  <span className="fst-italic ms-3 fs-5">posté {moment(el.created_at).locale("fr").format('LL LT')}</span>
                  </button>
                </div>
                <div
                  id={`faq${index}`}
                  className="accordion-collapse collapse"
                  role="tabpanel"
                  data-bs-parent="#faq-3"
                >
                  <div className="accordion-body p-3 bg-light m-2 rounded-3">
                    <div className="" style={{maxWidth:'800px'}}> 
                      <p className="text-break lh-2">
                      <div dangerouslySetInnerHTML={{ __html: el.contenue_notif }} />
                 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
export default React.memo(BoiteNotification)
