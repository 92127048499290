import React, { useEffect } from "react";
import isconnected from "../../../service/isconnected";
import axios from "axios";
import { organisme_API } from "../../../service/config";
import { useSelector } from "react-redux";
import { getCompte } from "../../../service/getApi/getCompte";
import { Badge } from "react-bootstrap";
import Partenaire from "./Partenaire";
import Section4 from "./Section4";

export default function Profile() {
  const dataRedux = useSelector((state) => state.compte);
  const categ =
    dataRedux?.categ_permis != null ? dataRedux?.categ_permis.split(",") : "";
  useEffect(() => {
    getCompte();
  }, []);
  return (
    <div style={{ position: "absolute", top: "-40px", width: "100%" }}>
      <div className="d-flex justify-content-center">
        <div className="card rounded-5" style={{ width: "95%" }}>
          <div className="card-body py-3">
            <div className="row justify-content-between ">
              <div className="col-4 col-md-4 col-12 d-flex justify-content-center pb-3">
                <div>
                  <h3 className="text-center">Status</h3>
                  <span className="display-6 ">
                    {dataRedux?.active_org ? (
                      dataRedux?.active_org == "Desactiver" ? (
                        <span className="text-warning">Désactivé</span>
                      ) : dataRedux?.active_org == "Activer" ? (
                        <span className="text-success">Activé</span>
                      ) : dataRedux?.active_org == "Bloquer" ? (
                        <span className="text-Danger">Bloqué</span>
                      ) : (
                        <span className="placeholder">loading</span>
                      )
                    ) : (
                      <span className="placeholder">loading</span>
                    )}
                  </span>
                </div>
              </div>
          {dataRedux?.categ_permis &&    <div className="col-4 col-md-4 col-12 d-flex border-lg-start border-lg-end border-md-start border-md-end border-top border-bottom justify-content-center py-3">
                <div>
                  <h3 className="text-center">Catégories</h3>
                  {dataRedux?.categ_permis != null ? (
                    <div className="d-flex gap-2 align-items-center justify-content-start">
                      {categ.map(
                        (el, i) =>
                          el != "" && (
                            <Badge bg="primary" className="p-2">
                              {el}
                            </Badge>
                          )
                      )}
                    </div>
                  ) : dataRedux?.categ_permis?.length == 0 ? (
                    <div>Aucune</div>
                  ) : (
                    <span className="placeholder">loading</span>
                  )}
                </div>
              </div>}
              <div className="col-4 col-md-4 col-12 d-flex justify-content-center">
                <div>
                  <h3 className="text-center">Notification</h3>
                  {dataRedux?.active_org ? (
                    dataRedux?.active_org == "Desactiver" ? (
                      <div
                        className="py-2"
                        style={{ maxHeight: "105px", overflowY: "auto" }}
                      >
                        <p className="px-2 alert alert-warning">
                          Veuillez contacter le ministère pour la régularisation
                          de votre agrément
                        </p>
                        <p className="px-2 alert alert-warning">
                          vous n'avez pas accès aux fonctionnalités
                        </p>
                      </div>
                    ) : (
                      <span className="display-6">Aucune notification</span>
                    )
                  ) : (
                    <span className="display-6 placeholder">Aucun permis</span>
                  )}
                </div>
              </div>
            </div>
            <Section4/>
            <Partenaire/>
          </div>
        </div>
      </div>
    </div>
  );
}
