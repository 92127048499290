import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Breadcumb from "../../../../../outils/BreadCumb/Breadcumb";

const TheorieRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="container-lg container-fluid pt-2">
      <div className="card p-3 rounded-3">
      <div className=" d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h1>Candidats / Examen théorique </h1>
        </div>
        <div className="d-flex flex-wrap justify-content-between">
        <div className="d-flex gap-1 flex-wrap ">
          {/* <div
            onClick={() => navigate("/theories")}
            className={`btn btn-secondary ${
              location.pathname === "/theories" ? "btn-primary" : ""
            }`}
          >
            aptes à candidater
          </div> */}
          <div
            onClick={() => navigate("/theories/attente")}
            className={`btn btn-secondary ${
              location.pathname === "/theories/attente" ? "btn-primary" : ""
            }`}
          >
            aptes à candidater
          </div>{" "}
          <div
            onClick={() => navigate("/theories/envoyer")}
            className={`btn btn-secondary ${
              location.pathname === "/theories/envoyer" ? "btn-primary" : ""
            }`}
          >
            Voir liste des candidats envoyée à l'ATT
          </div>
          <div
            onClick={() => navigate("/theories/valid")}
            className={`btn btn-secondary ${
              location.pathname === "/theories/valid" ? "btn-primary" : ""
            }`}
          >
           Voir liste des candidats Validés par l’ATT
          </div>
        </div>
      </div>
      </div>
      
      </div>
      <div className="mt-2">
        <Outlet />
      </div>
    </div>
  );
};

export default TheorieRoutes;
