import {
  faBookOpenReader,
  faBoxArchive,
  faCarSide,
  faChartPie,
  faFemale,
  faGraduationCap,
  faHouse,
  faListNumeric,
  faMale,
  faRectangleXmark,
  faSquare,
  faSquareCaretUp,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import BarChart from "./BarChart";
import DonutChart from "./DonutChart";
import NavStat from "./NavStat";
import axios from "axios";
import { statcategapi } from "../../../../service/config";

export default function StatCandidat() {
  const [donnee, setdonnee] = useState([]);
  const [load, setload] = useState(false);
  const getData = () => {
    setload(true);
    axios
      .get(statcategapi)
      .then((reponse) => {
        console.log(reponse);
        setdonnee(reponse.data);
        setload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-nowrap justify-content-between w-100 align-items-center">
          <div>
            <h2>
              <FontAwesomeIcon icon={faHouse} className="me-1" /> Statistique de
              suivi{" "}
            </h2>
            <h4>Statistique des Candidats</h4>
          </div>
          <div>
            <NavStat />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          {/* <h2>Filtres</h2> */}
          {/* <div className="row">
            <div className="col-lg-2 col-12">
              <div className="my-2">
                <label className="form-label">Années</label>
                <input
                  type={"date"}
                  className="form-control w-100"
                  name="annéé"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="my-2">
                <label className="form-label">Region</label>
                <select className="form-control w-100" name="autoecole">
                  <option value="">Freddy Ampasambazaha Fianarantdoz</option>
                  <option value="">Havana auto, Ambositra</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4 col-12 align-self-end">
              <div className="my-2 d-flex">
                <button className="btn btn-cyan w-100 mx-1">Valider</button>
                <div className="vr w-1 bg-secondary h-100"></div>
                <button className="btn btn-success w-100 mx-1">Exporter</button>
              </div>
            </div>
          </div> */}
          <div className="row align-items-center my-3">
          
            <div className="col-6">
            <h4 className="text-center">Répartition homme et femme</h4>
              <div
                className="d-flex  justify-content-center"
                style={{ maxHeight: "280px" }}
              >
             
                <DonutChart
                  donnee1={donnee?.repartition_homme_femme?.hommes}
                  donnee2={donnee?.repartition_homme_femme?.femmes}
                  taille={1}
                  label={["Homme", "Femme"]}
                  title={"Répartition homme et femme"}
                />
              </div>
              {/* <div
                className=""
                style={{ maxHeight: "280px" }}
              >
                <BarChart />
               
              </div> */}
            </div>
            <div className="col-6 h-100">
              <div className="row">
                <div className="col-12 p-1 h-100">
                  <div className="card py-3">
                    <div className="card-body">
                      <div className="row text-center">
                        <div className="col-lg-6 col-12">
                          <div className="">
                            <h3>Taux de reussite des candidats</h3>
                            <div className="display-6 fw-bold">
                              {donnee?.taux_reussite_global}%
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div>
                            <h3>Total candidat</h3>
                            <div className="display-6 fw-bold">
                              {donnee?.total_candidats}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
         <div className="mt-5 mb-2">
         <h2 className="text-center ">Taux de réussite des candidats par catégorie</h2>
         </div>
          <div className="row g-3">
            {donnee?.statistiques_par_categorie
              ? Object.entries(donnee?.statistiques_par_categorie).map(
                  ([category, stats]) => (
                    <div className="col-3" key={category}>
                      <div className="card card-link card-link-pop p-0 h-100">
                        <div
                          className="card-stamp card-stamp-sm"
                          style={{ opacity: 0.3 }}
                        >
                          <div
                            className="card-stamp-icon"
                            style={{
                              backgroundColor: "blue",
                              backgroundSize: "cover",
                              width: "40px",
                              height: "40px",
                              top: "-5px",
                              right: "-78px",
                            }}
                          >
                            <h3>{category}</h3>
                          </div>
                        </div>
                        <div className="row row-0 h-100">
                          <div className="col">
                            <div className="card-body pb-3 ps-2 h-100">
                              <div className="py-2">
                                <p className="text-center display-6 fw-bold">
                                  {stats.reussite}%
                                </p>
                                <p className="text-center text-muted">
                                  Candidats : {stats.candidats}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
              : null}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
