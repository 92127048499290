import { faCheckSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { generateRandomString } from "../../service/RandomString";
import { MultiSelect } from 'primereact/multiselect';
import { sortByProperty } from "../SortByProperty";


export default function SelectFinal({setFormValues,setError,formValues,name,choix,options,label,value,seteventy,error,max}) {
  const [selectedvalues, setselectedvalues] = useState(null);
  const animatedComponents = makeAnimated(); 
  const SortedValue = sortByProperty(options)
  
  let text = "";
  const [ito, setIto] = useState([]);
  const change = (choice) => {
    var nb = 0;
    text = "";
    console.log(choice)
    if (selectedvalues == null || choice?.length == 0) {
      setFormValues({ ...formValues, [name]: null });
      setError({ ...error, [name]: null });
    }
    choice?.forEach((element) => {
      nb++;
      text += element + ",";
      if (nb === choice.length) {
        setFormValues({ ...formValues, [name]: text });
        setError({ ...error, [name]: null });
      }
    });
    if (seteventy) {
      seteventy(generateRandomString(5));
    }

  };

  useEffect(() => {
    console.log(selectedvalues)
  change(selectedvalues)
   }, [selectedvalues]);
  useEffect(() => {
   if (choix) {
    let categ = choix.split(",");
    categ.pop()
    let valueCat = [];
    categ.map((i) => i != "" && valueCat.push({ value: i, label: i }));
    setselectedvalues(categ);
   
   }else{
    setIto(null)
   

   }
   
  }, [choix]);

  useEffect(()=>{
console.log(formValues)
  },[formValues])
  return (
    <div className="d-flex align-items-center gap-2 w-100">
    

      <MultiSelect style={{minWidth:'100px !important'}} display="comma" appendTo={"self"} value={selectedvalues} onChange={(e) => setselectedvalues(e.value)} options={SortedValue} optionValue={value} optionLabel={label} 
           showClear filter placeholder="Sélectionnez catégories" maxSelectedLabels={max ? max : 3}  className="w-full md:w-20rem" />
    </div>
  );
}




 