import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  addExaminateur,
  CATEGORIE_API,
  membre_API,
  organisme_API,
  Register_API,
} from "../../service/config";
import { toast } from "react-toastify";
import mtm from "../../assets/image/mtm.png";
import VerifyEmail from "./VerifyEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faEye,
  faEyeSlash,
  faHomeAlt,
  faSave,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { regions } from "../../service/auth/UtilApi";
import makeAnimated from "react-select/animated";
import SelectOne from "../../outils/Select/selectCustom";
import ConfirmationAnnulation from "./ConfirmationAnnulation";
import * as Yup from "yup";
import { Image } from "primereact/image";
import SelectFinal from "../../outils/Select/SelectFinal";

export default function AutoEcole_Sign(props) {
  const validationSchema = Yup.object().shape({
    nom_org: Yup.string().required("Ce champs est requis"),
    email_org: Yup.string()
      .required("Ce champs est requis")
      .email("Veuillez entrer un adresse email valide"),
    adresse_org: Yup.string().required("Ce champs est requis"),
    ville_org: Yup.string().required("Ce champs est requis"),
    province_org: Yup.string().required("Ce champs est requis"),
    cp_org: Yup.string().required("Ce champs est requis"),
    pays_org: Yup.string().required("Ce champs est requis"),
    web_org: Yup.string(),
    tel_org: Yup.string().required("Ce champs est requis"),
    logo_org: Yup.string().required("requis"),
    numero_agrement: Yup.string().required("Ce champs est requis"),
    delais_agrement: Yup.string().required("Ce champs est requis"),
    scan_agrement: Yup.string().required("requis"),
    statut_auto: Yup.string().required("Ce champs est requis"),
    nif_auto: Yup.string().required("Ce champs est requis"),
    stat_auto: Yup.string().required("Ce champs est requis"),
    scan_nif_recto: Yup.string().required("Ce champs est requis"),
    scan_nif_verso: Yup.string().required("Ce champs est requis"),
    rcs_auto: Yup.string().required("Ce champs est requis"),
    categ_permis: Yup.string().required("Ce champs est requis"),
    type_org: Yup.string(),
  });
  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const [ito, setIto] = useState([]);
  const [stepOne, setStepOne] = useState(false);
  const [listorg, setlistorg] = useState([]);
  const [showdp, setshowdp] = useState(false);
  const [verify, setverify] = useState(false);
  const [loadcateg, setloadcateg] = useState(false);
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    logo_org: null,
    numero_agrement: "",
    delais_agrement: "",
    scan_agrement: null,
    statut_auto: "",
    nif_auto: "",
    stat_auto: "",
    scan_nif_recto: "",
    scan_nif_verso: "",
    rcs_auto: "",
    categ_permis: "",
    type_org: "Auto-écoles",
  });
  const [vide, setvide] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    logo_org: null,
    numero_agrement: "",
    delais_agrement: "",
    scan_agrement: null,
    statut_auto: "",
    nif_auto: "",
    stat_auto: "",
    rcs_auto: "",
    categ_permis: "",
    type_org: "Auto-écoles",
  });
  const handleVide = () => {
    setFormValues(vide);
    setStepOne(false);
  };
  const change = (choice) => {
    setIto(choice);
    let text = "";
    var nb = 0;
    text = "";
    choice.forEach((element) => {
      nb++;
      text += element.label + ",";
      if (nb === choice.length) {
        setFormValues({ ...formValues, categ_permis: text });
        setError({ ...error, categ_permis: null });
      }
    });
  };
  const [error, setError] = useState([]);
  const [step, setStep] = useState(1);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [image, setImage] = useState({ scan_agrement: null, logo_org: null });

  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage({
        ...image,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
      setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null });
    }
  };
  const verif = async () => {
    setError({});
    const champs = Object.keys(formValues);
    for (let i = 0; i < champs.length; i++) {
      const name_champs = champs[i];
      try {
        await validationSchema
          .validateAt(name_champs, formValues, { abortEarly: true })
          .then(() => {
            if (step == 1 && name_champs == "logo_org") {
              setStep(step + 1);
            } else if (step == 2 && name_champs == "scan_agrement") {
              setStep(step + 1);
            } else if (name_champs == "categ_permis") {
              handleSubmit();
            }
          });
      } catch (error) {
        if (error.path === name_champs) {
          setError({ [name_champs]: error.message });
          return;
        }
      }
    }
  };

  const handleSubmit = async () => {
    setLoad(true);
    const apiErrors = {};
    try {
      await axios
        .post(organisme_API, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          toast.success("enregistrement reussi !");
          navigate("/login");
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            toast.error("Verifier votre saisi !");
            let champs = Object.keys(msg);
            for (let i = 0; i < champs.length; i++) {
              const element = champs[i];
              if (msg[element]) {
                apiErrors[element] = msg[element];
              }
            }
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
        });
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
    setError(apiErrors);
  };
  const getAllOrganisme = async () => {
    try {
      await axios
        .get(organisme_API + "?fields=id_org,nom_org")
        .then((reponse) => {
          console.log(reponse.data);
          let dataa = reponse.data.data;
          setlistorg(dataa);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCateg = async () => {
    setloadcateg(true);
    let getCateg = [];
    await axios
      .get(CATEGORIE_API)
      .then((rep) => {
        console.log(rep);
        const data = rep.data.data;

        data.forEach((element) => {
          getCateg.push({ value: element.id_categ, label: element.categ });
        });
        setloadcateg(false);
      })
      .catch((err) => {
        console.log(err);
        setloadcateg(false);
      });
    setOptions(getCateg);
  };

  useEffect(() => {
    getAllOrganisme();
    getAllCateg();
  }, []);
  return (
    <div className="contenu">
      <div className="">
        {step == 1 ? (
          <div className="info-generale">
            <div className="">
              <label className="form-label">Nom de votre organisme</label>
              <input
                className={"form-control " + (error.nom_org && "is-invalid")}
                name="nom_org"
                value={formValues.nom_org}
                onChange={handleChange}
                type="text"
                placeholder="Saisir nom "
              />
              {error.nom_org ? (
                <div className="invalid-feedback">{error.nom_org}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Email</label>
              <input
                className={"form-control " + (error.email_org && "is-invalid")}
                name="email_org"
                value={formValues.email_org}
                onChange={handleChange}
                type="email"
                placeholder="Saisir email"
              />
              {error.email_org ? (
                <div className="invalid-feedback">{error.email_org}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="row g-3">
              <div className="col-12 col-lg-6">
                <label className="form-label">Adresse</label>
                <input
                  className={
                    "form-control " + (error.adresse_org && "is-invalid")
                  }
                  name="adresse_org"
                  value={formValues.adresse_org}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir adresse"
                />
                {error.adresse_org ? (
                  <div className="invalid-feedback">{error.adresse_org}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <label className="form-label">ville</label>
                <input
                  className={
                    "form-control " + (error.ville_org && "is-invalid")
                  }
                  name="ville_org"
                  value={formValues.ville_org}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ville"
                />
                {error.ville_org ? (
                  <div className="invalid-feedback">{error.ville_org}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <label className="form-label">Région</label>
                <Select
                  options={regions}
                  value={[
                    {
                      value: formValues.province_org,
                      label: formValues.province_org,
                    },
                  ]}
                  onChange={(select) => {
                    setFormValues({
                      ...formValues,
                      province_org: select.value,
                    });
                    setError([]);
                  }}
                  placeholder="Selectionnez un région"
                />
                {error.province_org ? (
                  <div className="text-danger">{error.province_org}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <label className="form-label">Code postal</label>
                <input
                  className={"form-control " + (error.cp_org && "is-invalid")}
                  name="cp_org"
                  value={formValues.cp_org}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir code postal"
                />
                {error.cp_org ? (
                  <div className="invalid-feedback">{error.cp_org}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12">
                <label className="form-label">Pays</label>
                <input
                  className={"form-control " + (error.pays_org && "is-invalid")}
                  name="pays_org"
                  value={formValues.pays_org}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir pays"
                />
                {error.pays_org ? (
                  <div className="invalid-feedback">{error.pays_org}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12">
                <label className="form-label">Telephone (*)</label>
                <InputMask
                  onKeyUp={(e) => {
                    console.log(e.target.value);
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        tel_org: ["le champs telephone est requis"],
                      });
                    } else if (e.target.value !== "") {
                      setError({ ...error, tel_org: [] });
                    }
                  }}
                  onChange={handleChange}
                  mask="+261 39 99 999 99"
                  type="text"
                  className={
                    "form-control " +
                    (error.tel_org?.length > 0 && " is-invalid")
                  }
                  value={formValues.tel_org}
                  name="tel_org"
                  placeholder="Telephone"
                />

                {error.tel_org && (
                  <p className="invalid-feedback p-0 m-1">{error.tel_org}</p>
                )}
              </div>
              <div className="col-12">
                <div className="">
                  <label className="form-label">Logo </label>
                  <input
                    className={
                      "form-control " + (error.logo_org && "is-invalid")
                    }
                    name="logo_org"
                    id="logo_org"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.logo_org && (
                    <div className="invalid-feedback">{error.logo_org}</div>
                  )}
                </div>
                <div>
                  <Image src={image.logo_org} height="78" preview />
                </div>
              </div>
              <div className="col-12">
                <label className="form-label">Site web</label>
                <input
                  className={"form-control " + (error.web_org && "is-invalid")}
                  name="web_org"
                  value={formValues.web_org}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir Site web"
                />
                {error.web_org ? (
                  <div className="invalid-feedback">{error.web_org}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : step == 2 ? (
          <div className="agrement">
            <div className="row g-3">
              <div className="col-12">
                <label className="form-label">Numéro agrément</label>
                <input
                  className={
                    "form-control " + (error.numero_agrement && "is-invalid")
                  }
                  name="numero_agrement"
                  value={formValues.numero_agrement}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir Numéro agrément"
                />
                {error.numero_agrement ? (
                  <div className="invalid-feedback">
                    {error.numero_agrement}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12">
                <div className="">
                  <label className="form-label">Délai agrément </label>
                  <input
                    className={
                      "form-control " + (error.delais_agrement && "is-invalid")
                    }
                    name="delais_agrement"
                    id="delais_agrement"
                    type="date"
                    onChange={handleChange}
                    placeholder="délai d'agrément"
                    value={formValues.delais_agrement}
                  />
                  {error.delais_agrement && (
                    <div className="invalid-feedback">
                      {error.delais_agrement}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="">
                  <label className="form-label">Scan agrément </label>
                  <input
                    className={
                      "form-control " + (error.scan_agrement && "is-invalid")
                    }
                    name="scan_agrement"
                    id="scan_agrement"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.scan_agrement && (
                    <div className="invalid-feedback">
                      {error.scan_agrement}
                    </div>
                  )}
                </div>
                <div>
                  <Image src={image.scan_agrement} height="78" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="info_auto_ecole">
            <div className="row g-3">
              <div className="col-12">
                <label className="form-label">Statut auto-école</label>
                <select
                  className={
                    "form-control " + (error.statut_auto && "is-invalid")
                  }
                  name="statut_auto"
                  value={formValues.statut_auto}
                  onChange={handleChange}
                >
                  <option value="">Choisir parmi cette liste</option>
                  <option value="Société">Société</option>
                  <option value="Entreprise Individuelle">
                    Entreprise Individuelle
                  </option>
                </select>
                {error.statut_auto ? (
                  <div className="invalid-feedback">{error.statut_auto}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-8">
                <label className="form-label">
                  Numéro d'identification fiscale (NIF)
                </label>
                <input
                  className={"form-control " + (error.nif_auto && "is-invalid")}
                  name="nif_auto"
                  value={formValues.nif_auto}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir votre Nif"
                />
                {error.nif_auto ? (
                  <div className="invalid-feedback">{error.nif_auto}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-4">
                <label className="form-label">STAT</label>
                <input
                  className={
                    "form-control " + (error.stat_auto && "is-invalid")
                  }
                  name="stat_auto"
                  value={formValues.stat_auto}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir votre stat"
                />
                {error.stat_auto ? (
                  <div className="invalid-feedback">{error.stat_auto}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-6">
                <div className="">
                  <label className="form-label">Scan nif recto </label>
                  <input
                    className={
                      "form-control " + (error.scan_nif_recto && "is-invalid")
                    }
                    name="scan_nif_recto"
                    id="scan_nif_recto"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.scan_nif_recto && (
                    <div className="invalid-feedback">
                      {error.scan_nif_recto}
                    </div>
                  )}
                </div>
                <div>
                  <Image src={image.scan_nif_recto} height="78" />
                </div>
              </div>
              <div className="col-6">
                <div className="">
                  <label className="form-label">Scan nif verso </label>
                  <input
                    className={
                      "form-control " + (error.scan_nif_verso && "is-invalid")
                    }
                    name="scan_nif_verso"
                    id="scan_nif_verso"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.scan_nif_verso && (
                    <div className="invalid-feedback">
                      {error.scan_nif_verso}
                    </div>
                  )}
                </div>
                <div>
                  <Image src={image.scan_nif_verso} height="78" />
                </div>
              </div>
              <div className="col-12">
                <label className="form-label">
                  Registre du Commerce et des Sociétés (RCS)
                </label>
                <input
                  className={"form-control " + (error.rcs_auto && "is-invalid")}
                  name="rcs_auto"
                  value={formValues.rcs_auto}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir votre rcs"
                />
                {error.rcs_auto ? (
                  <div className="invalid-feedback">{error.rcs_auto}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12">
                <label className="form-label">Catégories à enseigner : </label>
                <div className="d-flex align-items-center gap-2">
                  {/* <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    className="w-75"
                    isMulti
                    placeholder="choix catégorie"
                    options={options}
                    name="id_categ"
                    value={ito}
                    onChange={(choice) => change(choice)}
                    noOptionsMessage={() => "plus d'options"}
                    isDisabled={loadcateg}
                    isLoading={loadcateg}
                  /> */}
                  <SelectFinal
                  
                    options={options}
                    load={false}
                    value={"value"}
                    label={"label"}
                    choix={""}
                    placeholder="choix catégorie"
                    setFormValues={setFormValues}
                    formValues={formValues}
                    setError={setError}
                    error={error}
                    name={"id_categ"}
                    max={7}
                  />
                  {/* <button
                    className="btn btn-cyan py-2 rounded-2"
                    type="button"
                    onClick={() => change(options)}
                  >
                    toutes
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="button d-flex justify-content-center gap-2 my-3">
        {step > 1 ? (
          <Button
            variant="secondary"
            className="py-2 rounded-2"
            disabled={step > 1 ? false : true}
            onClick={() => setStep(step - 1)}
          >
            <FontAwesomeIcon icon={faCaretLeft} className="me-2" />
            précedent
          </Button>
        ) : (
          <ConfirmationAnnulation handleVide={props.handleVide} />
        )}

        {step < 3 ? (
          <Button
            variant="success"
            className="py-2 rounded-2"
            disabled={step < 3 ? false : true}
            onClick={() => verif()}
          >
            suivant
            <FontAwesomeIcon icon={faCaretRight} className="ms-2" />
          </Button>
        ) : (
          <Button
            variant="primary"
            className="py-2 rounded-2"
            onClick={() => verif()}
            disabled={load}
          >
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Enregistrer
          </Button>
        )}
      </div>
      <div className="d-flex flex-column my-3">
        <p className="text-muted text-center">
          Vous avez déjà un compte,{" "}
          <Link to="/login" className="text-decoration-none text-primary">
            Connectez-vous
          </Link>
        </p>
      </div>
    </div>
  );
}
