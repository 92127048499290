export const IpAdresse = 'permis-conduire-mg.com'
export const IpAdresseReactApp ='127.0.0.1'
export const lien_vers_superadmin =`http://${IpAdresseReactApp}:3000`
export const adresse = `http://${IpAdresse}`
export const domaineAppPrincipal = 'localhost'
export const API_URL = `${adresse}:8080/api/`;
export const file_url =`${adresse}:8080/storage/`;
export const Register_API = API_URL + "auth/register"
export const LOGIN_API = API_URL + "auth/login"
export const ResendEmail_API = API_URL +"auth/resendEmailToken/"
export const LOGOUT = API_URL + "auth/logout" 
export const CATEGORIE_API = API_URL + "categorie"
export const CategorieByModule_API = API_URL + "categorieByModule/"
export const moduleByLesson = API_URL + "moduleByLesson/"
export const moduleByCategorie = API_URL + "moduleByCategorie/"
export const MODULE_API = API_URL + "modules"  
export const PROFILE_API = API_URL + "auth/profile" 
export const LESSON_API = API_URL + "lessons"
export const FichierLesson_API = API_URL +"fichierl"
export const ModuleCategorie_API = API_URL +"modulecategorie"
export const ListmoduleHateaos =API_URL+"moduleByCategorieHateaos/"
export const list4moduleHateaosByLangue =API_URL+"moduleByLangueHateaosLimit4/"
export const listallmodule=API_URL+"moduleByLangueHateaos/"
export const Questionnaire_API = API_URL +"questionnaires"
export const Reponse_API = API_URL +"reponses"
export const ListReponse_API=API_URL+"reponse/questionnaire/"
export const ListFichierq_API=API_URL+"fichierq/questionnaire"
export const Fichierq_API = API_URL +"fichierq"
export const Contact_API = API_URL +"contact"
export const commentmodule_API = API_URL + "commentairemodules/modules/"
export const commentlesson_API = API_URL + "commentairelessons"
export const lessonByModulesHateaos_API = API_URL+"lessonByModulesHateaos"
export const ListLessonHateaos_API = API_URL+"moduleByLessonHateaos"
export const AvisModule_API = API_URL+"avismodules"
export const AvisLesson_API = API_URL+"avislessons"
export const dixquestion_API = API_URL+ "dixQuestionnaresHateaos"
export const SearchModuleLesson_API=API_URL+"modulelessons"
export const modulebylanguecateg_API=API_URL+"moduleByCategorieByLangueHateaos/"
export const modulequestionrep =API_URL+"moduleQuestionaireReponseHateaos"
export const cursus_API = API_URL+"cursus"
export const Pourcentage_API=API_URL+"pourcentages"
export const TexteLois_API = API_URL+"textetlois"
export const FichierLois_API =API_URL+"fichiert"
export const ACTU_API =API_URL+"actualites"
export const SENSI_API=API_URL+"sensibilisations?type_sens=Reseau"
export const SENSI_API_ID = API_URL+"sensibilisations"
export const TypeLois_API=API_URL+"typelois"
export const loisByType_API =API_URL+"loisByType"
export const reponseContact_API =API_URL+"repcontact"
export const reponseByContact =API_URL+"repbycontact"
export const notificationCli_API =API_URL+"notifications"
export const organisme_API =API_URL+"organismes"
export const membre_API =API_URL+"membres"
export const candidat_API =API_URL+"candidats"
export const candidat_API_V2 =API_URL+"v2/candidats"
export const updateTypeCand_API = API_URL + "updateTypeCand"
export const userByEmail_API =API_URL+"userByEmail"
export const affecttheorie_API  = API_URL+ "affectheories"
export const affectpratique_API  = API_URL+ "affecpratiques"
export const getcentreByatt = API_URL+"getCentreByAtt?type_org="
export const getCandidat = API_URL + "v2/getCandidatExamen"
export const Examen_API  = API_URL + "examens"
export const listeCandTheorie = API_URL + "validationtheories"
export const getCandidatBeforeVld = API_URL + "getCandidats"
export const ChangerMDP_API = API_URL+"account/changeMdp"
export const typeExam = API_URL + "typeexamens"
export const listcandexampratique = API_URL+"v2/getCandidatExamenPratique" /*old liste cand ATT*/
export const listAffecpratiques = API_URL + "v2/affecpratiques" /*NEW liste cand ATT*/
export const getValidCandidatPratique = API_URL + "getValidCandidatPratique"
export const examenspratiques_API = API_URL + "examenspratiques"
export const listCandidatValidForCentre = API_URL+"listCandidatValidForCentre"
export const getListExaminateur = API_URL + "examianteurByOrganisme/"
export const  getcandidatRetardTheorie = API_URL + "candidatRetardTheorie"
export const addExaminateur = API_URL +"membres/inscriptionExaminateur"
export const motsministres = API_URL +"motsministres"
export const mdpOublierByEmail = API_URL +"account/mdpOublierByEmail"
export const examinateurByStatus = API_URL+ "examianteurByStatus?active_memb="
export const validerExaminateurbyMtm = API_URL+"valideExaminateurByMTM"
export const resulTheorie = API_URL+"getReussiCandidatTheorie"
export const paramNotes  = API_URL + "paramnotes"
export const affecterExaminateur = API_URL + "affecterExaminateur"
export const validationtheoriesHateaos = API_URL + "validationtheoriesHateaos"
export const validationpratiqueHateaos = API_URL + "validationpratiqueHateaos"
export const cursus_Cand = API_URL + "pourcentagebycand?id_cand="
export const activeMembr =API_URL+"activeMembre"
export const getReussiCandidatTheorie = API_URL + "/v2/getReussiCandidatTheorie"
export const inscriptionMoniteur =API_URL+"membres/inscriptionMoniteur"
export const moniteurByStatus =API_URL +"moniteurByStatus?active_memb="
export const  moniteurByOrganisme =API_URL +"moniteurByOrganisme/"
export const statusMoniteur = API_URL + "valideMoniteurByMTM/"
export const affecterMoniteur = API_URL + "affecterMoniteur/"
export const Historique_api = API_URL + "historique"
export const GetMembreByMail = API_URL + "membreByEmail"
export const getCandidatoncim = API_URL + "candidat/candidatCim"
export const getCandidatoncimall = API_URL + "candidat/candidatPratique"
export const refuserPratique = API_URL + "candidat/refuserPratique"
export const refuserTheorie = API_URL + "candidat/refuserTheorie"
export const accepterPratique = API_URL + "candidat/accepterPratique"
export const accepterTheorie = API_URL + "candidat/accepterTheorie"
export const vehicule = API_URL + "vehicules"
export const statgeneral = API_URL + "statistiques"
export const statmodule = API_URL + "statistiquesModules"
export const statautoecole = API_URL + "statistiquesAutoEcole"
export const countexaminateur = API_URL + "compterExaminateur"
export const AjoutVisacim = API_URL + "updateCandByCim/"
export const materielapi = API_URL + "materiels"
export const statcategapi = API_URL + "statistiquesCategorie"


export const ExamenListTheo = (orderBy,order)=> {
   let url = `${API_URL}examens?orderBy=${orderBy? orderBy : 'id_exam'}&order=${order ? order : 'desc'}`;
     return url;
}

export const ResRedoubleTheo = () => {
    let url = `${API_URL}v2/getRedoubleCandidatTheorie`
    return url;
}

export const ResPassTheo = () => {
    let url = `${API_URL}v2/getReussiCandidatTheorie`
    return url;
}

export const ExamenListPratique = (orderBy,order)=> {
    let url = `${API_URL}examenspratiques?orderBy=${orderBy ? orderBy : 'id_examp'}&order=${order ? order : 'desc'}`;
    return url;
}

 
 export const ResRedoublePratique = () => {
    let url = `${API_URL}v2/getRedoubleCandidatPratique`
    return url;
}


export const ResPassPratique = () => {
    let url = `${API_URL}v2/getReussiCandidatPratique`
    return url;
}

export const ResPassPratiqueV1 = () => {
    let url = `${API_URL}getReussiCandidatPratique`
    return url;
}


export const MapPratique = (id_cand) => {
    let url = `${API_URL}notepratiques${id_cand ? '?id_cand='+id_cand+'' : ''}`
    return url;
}

export const UserEndpoint = API_URL + "users"