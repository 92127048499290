import {
  faAdd,
  faCheckCircle,
  faCross,
  faEdit,
  faEye,
  faFileDownload,
  faFilter,
  faHouse,
  faMapMarked,
  faMapPin,
  faPhone,
  faTrash,
  faUndoAlt,
  faUserGraduate,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import CheckBox from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import image from "../../../assets/image/imgtest/image";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import InputMask from "react-input-mask";
import {
  candidat_API,
  CATEGORIE_API,
  file_url,
  organisme_API,
  updateTypeCand_API,
  userByEmail_API,
} from "../../../service/config";
import { regions } from "../../../service/auth/UtilApi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SelectCustom from "../../../outils/Select/SelectComponent";
import moment from "moment";
import CardHover from "../../../outils/HoverCard/CardHover";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Evolution from "../../Compte/Grand Public/Evolution";

export default function Apprenants() {
  const [choix, setchoix] = useState([]);
  const [filtre, setfiltre] = useState("");
const navigate = useNavigate()
  const  renderSelectableCell = (dateInscription) => {
    const dateInscriptionApprenant = moment(dateInscription.date_insc_cand);
    const dateActuelle = moment(); // Obtenir la date actuelle 
    // Vérifier si la différence entre la date d'inscription et la date actuelle est de 3 mois ou plus
    if (moment(dateInscriptionApprenant).isSameOrBefore(dateActuelle.subtract(3, 'months'), 'day') && dateInscription.type_cand == 'Apprenants') {
      return false;
    }else {
      return true;
    }
  }
  // const renderSelectableCell = (row) => {
  //   const date_insc_cand = moment(row.date_insc_cand);
  //   if (row.date_insc_cand  < moment().subtract(3,'months').toDate()  && row.type_cand == "Apprenants") {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  var text = "";

  const columns = [
    {
      name: "Photo",
      width:'8%',
      selector: (row) => (
        <Image src={file_url + row.photo_cand} alt="Image" width="50" preview />
      ),
      sortable: true,
    },
    {
      name: "Nom et Prénom",
      wrap: true,
      selector: (row) => row.nom_cand + " " + row.prenom_cand,
      sortable: true,
    },
    {
      name: "Permis",
      wrap:true,
      selector: (row) => {
        let cat = row.categ_permis?.split(",");
        return (
          <div className="d-flex flex-wrap gap-1 p-1">
            {cat?.map(
              (i) => i.length > 0 && <span className="badge bg-cyan">{i}</span>
            )}
          </div>
        );
      },
      sortable: true,
    },
      {
        name: "Email",
        selector: (row) => row.email_cand,
        sortable: true,
        width: "22%",
        wrap:true
      },
    {
      name: "téléphone",
      width: "15%",
      selector: (row) => (
        <>
          {" "}
          <FontAwesomeIcon icon={faPhone} className="pe-2" />
          {row.tel_cand}
        </>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "statut",
      wrap:true,
      selector: (row) => row.status_examen_cand,
      sortable: true,
    },

    {
      
      wrap:true,
      width:'185px',
      name: "Détails",
      cell: (row) => (
        <div className="d-flex py-2 gap-1">
          <button
            className="btn btn-primary"
            onClick={() => DetailsApprennant(row)}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
         <Evolution row={row}/>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const selected = [
    {
      width: "8%",
      name: "Photo",
      selector: (row) => (
        <Image src={file_url + row.photo_cand} alt="Image" width="50" preview />
      ),
      sortable: true,
    },
    {
      width: "23%",
      name: "Nom et prenom",
      selector: (row) => (
    <CardHover candidat={row}/>
      ),
      sortable: true,
    },
    {
      name: "Permis",
      width: "10%",
      selector: (row) => {
        let cat = row.categ_permis ? row?.categ_permis?.split(",") : [];
        return (
          <div className="d-flex gap-1">
            {cat?.map(
              (i) => i.length > 0 && <span className="badge bg-cyan">{i}</span>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "téléphone",
      selector: (row) => (
        <>
          {" "}
          <FontAwesomeIcon icon={faPhone} className="pe-2" />
          {row.tel_cand}
        </>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Détails",
      cell: (row) => (
        <div className="d-flex gap-1">

          <button
            className="btn btn-primary"
            onClick={() => DetailsApprennant(row)}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setSelectedRows(selectedRows.filter((el)=>el.id_cand != row.id_cand))}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState([]);
  const [Reg, setReg] = useState({});
  const [categorie, setcategorie] = useState([]);
  const [landing, setlanding] = useState(true);
  const [loading, setloading] = useState(false);
  const abortController = new AbortController();
  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState(false);
  const [cin, setcin] = useState(null);
  const [cin_verso, setcin_verso] = useState(null);
  const [certificat, setcertificat] = useState(null);
  const [ActeNaiss, setActeNaiss] = useState(null)
  const [ito, setIto] = useState([]);
  const [loadcateg, setloadcateg] = useState(false);
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const [selectapp,setselectapp] = useState(false)
  const handleselectapp = ()=>{
    setselectapp(!selectapp)
  }
  const [formValues, setFormValues] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    photo_cand: null,
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    adresse_cand: "",
    ville_cand: "",
    province_cand: "",
    cp_cand: "",
    active_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    id_org: "",
  });
  const change = (choice) => {
    setIto(choice);
    var nb = 0;
    text = "";
    choice.forEach((element) => {
      nb++;
      text += element.label + ",";
      if (nb === choice.length) {
        setFormValues({ ...formValues, categ_permis: text });
        setError({ ...error, categ_permis: null });
      }
    });
  };
  const [vide, setvide] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    cin_verso_cand: "",
    photo_cand: null,
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    adresse_cand: "",
    ville_cand: "",
    province_cand: "",
    cp_cand: "",
    active_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    id_org: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [img, setImage] = useState(null);

  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null });
    }
    if (e.target.name === "scan_cin_can") {
      setcin(URL.createObjectURL(e.target.files[0]));
    }else if (e.target.name === "cin_verso_cand") {
      setcin_verso(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name === "scan_certifica_cand") {
      setcertificat(URL.createObjectURL(e.target.files[0]));
    }else if (e.target.name === "acte_naiss") {
      setActeNaiss(URL.createObjectURL(e.target.files[0]));
    } else {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const getAllCateg = async () => {
    setloadcateg(true);
    let getCateg = [];
    await axios
      .get(CATEGORIE_API,{
        signal: abortController.signal,
        })
      .then((rep) => {
        console.log(rep);
        const data = rep.data.data;

        data.forEach((element) => {
          getCateg.push({ value: element.id_categ, label: element.categ });
        });
        setloadcateg(false);
      })
      .catch((err) => {
        console.log(err);
        setloadcateg(false);
      });
    setOptions(getCateg);
  };

  const handleSubmit = () => {
    console.log(formValues);

    if (!formValues.nom_cand) {
      setError({ ...error, nom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.prenom_cand) {
      setError({ ...error, prenom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("prenom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cin_cand) {
      setError({ ...error, cin_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cin_verso_cand) {
      setError({ ...error, cin_verso_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_verso_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.sexe_cand) {
      setError({ ...error, sexe_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("sexe_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.naiss_cand) {
      setError({ ...error, naiss_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("naiss_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.nationalite_cand) {
      setError({ ...error, nationalite_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nationalite_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_cand) {
      setError({ ...error, email_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("email_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    }else if (formValues.photo_cand === null) {
      setError({ ...error, photo_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("photo_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_cand) {
      setError({ ...error, ville_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_cand) {
      setError({ ...error, province_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("province_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_cand) {
      setError({ ...error, adresse_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_cand) {
      setError({ ...error, cp_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_cand) {
      setError({ ...error, tel_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.categ_permis) {
      setError({ ...error, categ_permis: "ce champs est réquis !" });
      const input = document.getElementsByName("categ_permis")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.date_insc_cand) {
      setError({ ...error, date_insc_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("date_insc_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else {
      setLoad(true);
      const apiErrors = {};
      axios
        .post(candidat_API, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("enregistrement reussi !");
          getCandidats();
          setImage(null);
          setcin(null);
          setcin_verso(null);
          setcertificat(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.nom_cand = msg.nom_cand;
            apiErrors.prenom_cand = msg.prenom_cand;
            apiErrors.cin_cand = msg.cin_cand;
            apiErrors.cin_verso_cand = msg.cin_verso_cand;
            apiErrors.photo_cand = msg.photo_cand;
            apiErrors.sexe_cand = msg.sexe_cand;
            apiErrors.naiss_cand = msg.naiss_cand;
            apiErrors.nationalite_cand = msg.nationalite_cand;
            apiErrors.fonction_memb = msg.fonction_memb;
            apiErrors.adresse_cand = msg.adresse_cand;
            apiErrors.ville_cand = msg.ville_cand;
            apiErrors.active_cand = msg.active_cand;
            apiErrors.province_cand = msg.province_cand;
            apiErrors.cp_cand = msg.cp_cand;
            apiErrors.pays_cand = msg.pays_cand;
            apiErrors.tel_cand = msg.tel_cand;
            apiErrors.email_cand = msg.email_cand;
            apiErrors.categ_permis = msg.categ_permis;
            apiErrors.date_insc_cand = msg.date_insc_cand;
            apiErrors.acte_naiss =msg.acte_naiss
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
            setImage(null);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
        });
      setError(apiErrors);
    }
  };
  const handleUpdate = () => {

    if (!formValues.nom_cand) {
      setError({ ...error, nom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.prenom_cand) {
      setError({ ...error, prenom_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("prenom_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cin_cand) {
      setError({ ...error, cin_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    }else if (!formValues.cin_verso_cand) {
      setError({ ...error, cin_verso_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_verso_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.sexe_cand === "") {
      setError({ ...error, sexe_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("sexe_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.sexe_cand === true) {
      setFormValues({ ...formValues, sexe_cand: "1" });
    } else if (formValues.sexe_cand === false) {
      setFormValues({ ...formValues, sexe_cand: "0" });
    } else if (!formValues.naiss_cand) {
      setError({ ...error, naiss_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("naiss_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.nationalite_cand) {
      setError({ ...error, nationalite_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("nationalite_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_cand) {
      setError({ ...error, email_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("email_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_cand) {
      setError({ ...error, ville_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_cand) {
      setError({ ...error, province_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("province_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_cand) {
      setError({ ...error, adresse_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_cand) {
      setError({ ...error, cp_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_cand) {
      setError({ ...error, tel_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.categ_permis) {
      setError({ ...error, categ_permis: "ce champs est réquis !" });
      const input = document.getElementsByName("categ_permis")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.date_insc_cand) {
      setError({ ...error, date_insc_cand: "ce champs est réquis !" });
      const input = document.getElementsByName("date_insc_cand")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else {
      setLoad(true);
      const apiErrorss = {};
      axios
        .post(candidat_API + `/${formValues.id_cand}?_method=PUT`, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("Modification reussi !");
          getCandidats();
          setImage(null);
          setcin(null);
          setcertificat(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (error.response.status == 401) {
            toast.info("Vous n'êtes pas autorisée a effectuer cette action !");
          }
          if (msg) {
            apiErrorss.nom_cand = msg.nom_cand;
            apiErrorss.prenom_cand = msg.prenom_cand;
            apiErrorss.cin_cand = msg.cin_cand;
            apiErrorss.cin_verso_cand = msg.cin_verso_cand;
            apiErrorss.photo_cand = msg.photo_cand;
            apiErrorss.sexe_cand = msg.sexe_cand;
            apiErrorss.naiss_cand = msg.naiss_cand;
            apiErrorss.nationalite_cand = msg.nationalite_cand;
            apiErrorss.fonction_memb = msg.fonction_memb;
            apiErrorss.adresse_cand = msg.adresse_cand;
            apiErrorss.ville_cand = msg.ville_cand;
            apiErrorss.active_cand = msg.active_cand;
            apiErrorss.province_cand = msg.province_cand;
            apiErrorss.cp_cand = msg.cp_cand;
            apiErrorss.pays_cand = msg.pays_cand;
            apiErrorss.tel_cand = msg.tel_cand;
            apiErrorss.email_cand = msg.email_cand;
            apiErrorss.categ_permis = msg.categ_permis;
            apiErrorss.date_insc_cand = msg.date_insc_cand;
            apiErrorss.acte_naiss=msg.acte_naiss
          }
        });
      setError(apiErrorss);
    }
  };

  const ValiderCandidat = () => {
    setLoad(true);
    for (let index = 0; index < selectedRows.length; index++) {
      const element = selectedRows[index];
      axios
        .put(updateTypeCand_API + "/" + element.id_cand,{ type_cand: "Candidats"})
        .then((reponse) => {
          console.log(reponse.data);
          if (index == selectedRows.length - 1) {
            setLoad(false);
            toast.success(
              "les apprennants sélectionner sont maintenant des candidats !"
            );
            handleselectapp();
            settoggledClearRows(!toggledClearRows);
           getCandidats() 
          }
        })
        .catch((error) => {
          toast.error(error.response.message);
          if (index == selectedRows.length - 1) {
            console.log(error);
            setLoad(false);
            toast.error(error.response.message);
            settoggledClearRows(!toggledClearRows);
          }
        });
    }
  };

  const getCandidats = () => {
    setLoad(true);
    axios
      .get(candidat_API + "?order=asc&limit=",{
        signal: abortController.signal,
        })
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllOrganisme = () => {
    setLoad(true);
    axios
      .get(organisme_API + "?order=asc&limit=4")
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const findApprenant = () => {
    setloading(true);
    if (formValues.email_cand) {
      axios
        .get(userByEmail_API + "?email=" + formValues.email_cand + "",{
          signal: abortController.signal,
          })
        .then((reponse) => {
          console.log(reponse.data.data[0]);
          if (reponse.data.data.length > 0) {
            toast.info(
              "Les informations concernant l’apprenant ont été retrouvées !"
            );
          }
          if (reponse.data.data[0]) {
            let c = reponse.data.data[0];
            Object.assign(formValues, {
              nom_cand: c.nom_util,
              prenom_cand: c.prenom_util,
              tel_cand: c.tel_util,
              email_cand: c.email,
              cin_cand : c.cin_util
            });

            console.log(formValues);
          }
          setloading(false);
          setlanding(false);
          console.log(formValues);
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    } else {
      setloading(false);
      setlanding(false);
    }
  };

  const DetailsApprennant = (element) => {
    setlanding(false);
    setmode(true);
    setReg({ value: element.province_cand, label: element.province_cand });
    let categ = element?.categ_permis?.split(",");
    let valueCat = [];
    categ?.map((i) => i != "" && valueCat.push({ value: i, label: i }));
    setchoix(element.categ_permis);
    setIto(valueCat);
    const { photo_cand, scan_cin_can, scan_certifica_cand, ...formfiltrer } =
      element;
    setFormValues(formfiltrer);
    setcin(file_url + element.scan_cin_can);
    setcin_verso(file_url + element.cin_verso_cand)
    setcertificat(file_url + element.scan_certifica_cand);
    setImage(file_url + element.photo_cand);
    setShow(true);
  };


  const savePhotosToFolder = async () => {
    try {
      // 1. Ouvrir le sélecteur de dossier
      const dirHandle = await window.showDirectoryPicker();

      for (const candidate of selectedRows) {
        // 2. Créer un sous-dossier pour chaque candidat
        const candidateDir = await dirHandle.getDirectoryHandle(candidate.nom_cand, { create: true });

        // 3. Télécharger l'image

        const response = await fetch(file_url+candidate.photo_cand,{ mode: "no-cors" }).catch((e)=>console.log(e));
        const blob = await response.blob();

        // 4. Enregistrer le fichier dans le sous-dossier
        const fileHandle = await candidateDir.getFileHandle(`${candidate.nom_cand}.jpg`, { create: true });
        const writableStream = await fileHandle.createWritable();
        await writableStream.write(blob);
        await writableStream.close();
      }
toast.success("Photos enregistrées avec succès !")
    } catch (error) {
      console.error("Erreur lors de l'enregistrement des photos :", error);
      
      // toast.error("Une erreur s'est produite. Vérifiez si votre navigateur prend en charge la File System Access API.")
    }
  };


  useEffect(() => {
    getCandidats();
    getAllCateg();
    return () => {
      abortController.abort();
    };

  }, []);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button
            variant="danger"
            disabled={load}
            onClick={() => handleSubmit()}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={selectapp}
        onHide={handleselectapp}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Les apprenants sélectionnées</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <DataTable
            className="custom-table"
            title={
              <div className="d-flex justify-content-between">
                <h4>Liste des apprenants</h4>
              </div>
            }
            columns={selected}
            highlightOnHover
            clearSelectedRows={toggledClearRows}
            data={selectedRows}
           
            contextMessage={{
              singular: "élement",
              plural: "élements",
              message: "sélectionnées",
            }}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{handleselectapp();settoggledClearRows(!toggledClearRows)}}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={load}
            onClick={() => ValiderCandidat()}
          >
            valider candidature
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faUserGraduate} className="me-1" /> Les
            apprenants{" "}
          </h2>
        </div>
        <div className="d-flex gap-1">
          <Button
            variant="primary"
            onClick={() => {
              setReg(null);
              setmode(false);
              setFormValues(vide);
              setImage(null);
              setcertificat(null);
              setcin(null);
              setchoix(null);
              handleShow();
              setlanding(true);
            }}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouvel apprenant
          </Button>
        </div>
      </div>

      {load ? (
        <div className="w-100 d-flex justify-content-center py-5">
          <TailSpin
            height="80"
            width="80"
            color="#22577A"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          
          <DataTable
            className="custom-table"
            title={
              <div className="d-flex justify-content-between">
                <h4>Liste des apprenants</h4>
                <div className="d-flex gap-2">
                  <button
                    className={`btn ${filtre == '' ? 'btn-cyan' : 'btn-secondary'} btn-sm`}
                    onClick={() => setfiltre("")}
                  >
                    {" "}
                    Tous
                  </button>
                  <button
                    className={`btn ${filtre == '3mois' ? 'btn-cyan' : 'btn-secondary'} btn-sm`}
                    onClick={() => setfiltre("3mois")}
                  >
                  Aptes à candidater
                  </button>
                  <button
                    className={`btn ${filtre == 'cand' ? 'btn-cyan' : 'btn-secondary'} btn-sm`}
                    onClick={() => setfiltre("cand")}
                  >
                    Qui sont déjà candidats
                  </button>
                </div>
              </div>
            }
            columns={columns}
            highlightOnHover
            selectableRows
            clearSelectedRows={toggledClearRows}
            data={
              filtre == ""
                ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : filtre == "3mois"
                ? data.filter(
                    (row) =>
                    renderSelectableCell(row) == false &&
                      row.type_cand == "Apprenants"
                  ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : filtre == "cand"
                ? data.filter((row) => row.type_cand == "Candidats").sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : null
            }
            onSelectedRowsChange={(rows) => {
              setSelectedRows(rows.selectedRows);
            }}
            progressPending={load}
            contextActions={
              <div className="d-flex gap-3">
           
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={() => {
                    settoggledClearRows(!toggledClearRows);
                  }}
                >
                  <FontAwesomeIcon icon={faUndoAlt} className="" />
                </Button>
                <button
                  className="btn btn-info me-2"
                  onClick={savePhotosToFolder} disabled={selectedRows.length === 0}
                >
                  <FontAwesomeIcon icon={faFileDownload} className="pe-2" />
                  Enregistrer dans un dossier
                </button>
                <button
                  className="btn btn-cyan me-2"
                  onClick={() => {
                   handleselectapp()
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} className="pe-2" /> voir
                  la liste des selectionné
                </button>
              </div>
            }
            // selectableRowDisabled={renderSelectableCell}
            //  conditionalRowStyles={[{
            //   when: renderSelectableCell,
            //   style:{
            //     '& .checkbox-column':{
            //       visibility:'hidden',
            //     }
            //   }
            //  }]}
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={{
              singular: "élement",
              plural: "élements",
              message: "sélectionnées",
            }}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={landing ? "md" : "xl"}
        fullscreen={!landing}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {landing ? (
              <div className="col-12 d-flex justify-content-center align-items-center gap-3">
                <div>
                  <h3 className="mb-3">
                    Entrer l'adresse email du nouveau apprenant :{" "}
                  </h3>
                  <input
                    type="email"
                    name="email_cand"
                    value={formValues.email_cand}
                    className="form-control"
                    placeholder="taper ici son adresse"
                    onChange={handleChange}
                  />
                  <Button
                    onClick={findApprenant}
                    className={
                      "btn btn-primary rounded-5 my-3 w-100 text-center"}
                  disabled={loading}>
                    
                    Suivant
                  </Button>
                </div>
              </div>
            ) : (
              <div className="col-12 d-flex align-item-stretch">
                <div className="row g-2">
                  <div className="col-lg-6 col-12 d-flex align-item-stretch">
                    <div className="card w-100">
                      <div className="card-body">
                        <div className="row g-2">
                          <div className="col-lg-6">
                            <label className="form-label">Nom</label>
                            <input
                              className={
                                "form-control " +
                                (error.nom_cand && "is-invalid")
                              }
                              name="nom_cand"
                              value={formValues.nom_cand}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir nom "
                            />
                            {error.nom_cand ? (
                              <div className="invalid-feedback">
                                {error.nom_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">Prenom</label>
                            <input
                              className={
                                "form-control " +
                                (error.prenom_cand && "is-invalid")
                              }
                              name="prenom_cand"
                              value={formValues.prenom_cand}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir prenom"
                            />
                            {error.prenom_cand ? (
                              <div className="invalid-feedback">
                                {error.prenom_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">CIN</label>
                            <input
                              className={
                                "form-control " +
                                (error.cin_cand && "is-invalid")
                              }
                              name="cin_cand"
                              value={formValues.cin_cand}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir CIN"
                            />
                            {error.cin_cand ? (
                              <div className="invalid-feedback">
                                {error.cin_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">Sexe</label>
                            <select
                              className={
                                "form-control " +
                                (error.sexe_cand && "is-invalid")
                              }
                              name="sexe_cand"
                              value={
                                formValues.sexe_cand === true
                                  ? "1"
                                  : formValues.sexe_cand === false
                                  ? "0"
                                  : formValues.sexe_cand === ""
                                  ? formValues.sexe_cand
                                  : formValues.sexe_cand
                              }
                              onChange={handleChange}
                            >
                              <option>choisir sexe ...</option>
                              <option value={0}>Femme</option>
                              <option value={1}>Homme</option>
                            </select>
                            {error.sexe_cand ? (
                              <div className="invalid-feedback">
                                {error.sexe_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">
                              Date de naissance
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.naiss_cand && "is-invalid")
                              }
                              name="naiss_cand"
                              value={formValues.naiss_cand}
                              onChange={handleChange}
                              type="date"
                              placeholder="Chosir date"
                            />
                            {error.naiss_cand ? (
                              <div className="invalid-feedback">
                                {error.naiss_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">Nationalité</label>
                            <input
                              className={
                                "form-control " +
                                (error.nationalite_cand && "is-invalid")
                              }
                              name="nationalite_cand"
                              value={formValues.nationalite_cand}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir son nationalité"
                            />
                            {error.nationalite_cand ? (
                              <div className="invalid-feedback">
                                {error.nationalite_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="my-3">
                          <label className="form-label">Email</label>
                          <input
                            className={
                              "form-control " +
                              (error.email_cand && "is-invalid")
                            }
                            name="email_cand"
                            value={formValues.email_cand}
                            onChange={handleChange}
                            type="email"
                            placeholder="Saisir email"
                          />
                          {error.email_cand ? (
                            <div className="invalid-feedback">
                              {error.email_cand}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="my-3">
                        <div className="row align-items-center">
                          <div className="col-8">
                            <div className="">
                              <label className="form-label">PHOTO </label>
                              <input
                                className={
                                  "form-control " +
                                  (error.photo_cand && "is-invalid")
                                }
                                name="photo_cand"
                                id="photo_cand"
                                type="file"
                                onChange={onImageChange}
                                accept="image/*"
                              />
                              {error.photo_cand && (
                                <div className="invalid-feedback">
                                  {error.photo_cand}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-4 align-self-end">
                            {img && (
                              <Image src={img} alt="Image" width="60" preview />
                            )}
                          </div>
                        </div>
                        </div>
                        <div className="my-3">
                        <div className="row align-items-center">
                          <div className="col-8">
                            <div className="">
                              <label className="form-label">Scan de l'acte de naissance </label>
                              <input
                                className={
                                  "form-control " +
                                  (error.acte_naiss && "is-invalid")
                                }
                                name="acte_naiss"
                                id="acte_naiss"
                                type="file"
                                onChange={onImageChange}
                                accept="image/*"
                              />
                              {error.acte_naiss && (
                                <div className="invalid-feedback">
                                  {error.acte_naiss}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-4 align-self-end">
                            {ActeNaiss && (
                              <Image src={ActeNaiss} alt="Image" width="60" preview />
                            )}
                          </div>
                        </div>
                        </div>
                        <div className="my-3 g-2 row align-items-center">
                          <div className="col-12">
                          <label className="form-label"> Scan CIN de l'apprenant </label>
                         
                          </div>
                          <div className="col-6">
                            <div className="">
                              <label className="form-label">
                                recto :{" "}
                              </label>
                              <input
                                className={
                                  "form-control " +
                                  (error.scan_cin_can && "is-invalid")
                                }
                                name="scan_cin_can"
                                id="scan_cin_can"
                                type="file"
                                onChange={onImageChange}
                                accept="image/*"
                              />
                              {error.scan_cin_can && (
                                <div className="invalid-feedback">
                                  {error.scan_cin_can}
                                </div>
                              )}
                               {cin && (
                              <Image src={cin} alt="Image" width="60" preview />
                            )}
                            </div>
                          </div>
                          <div className="col-6 ">
                          <div className="">
                              <label className="form-label">
                                 verso :{" "}
                              </label>
                              <input
                                className={
                                  "form-control " +
                                  (error.cin_verso_cand && "is-invalid")
                                }
                                name="cin_verso_cand"
                                id="cin_verso_cand"
                                type="file"
                                onChange={onImageChange}
                                accept="image/*"
                              />
                              {error.cin_verso_cand && (
                                <div className="invalid-feedback">
                                  {error.cin_verso_cand}
                                </div>
                              )}
                               {cin_verso && (
                              <Image src={cin_verso} alt="Image" width="60" preview />
                            )}
                            </div>
                          </div>
                        </div>
                        <div className="my-3 row align-items-center">
                          <div className="col-8">
                            <div className="">
                              <label className="form-label">
                                Scan certificat de résidence de l'apprenant{" "}
                              </label>
                              <input
                                className={
                                  "form-control " +
                                  (error.scan_certifica_cand && "is-invalid")
                                }
                                name="scan_certifica_cand"
                                id="scan_certifica_cand"
                                type="file"
                                onChange={onImageChange}
                                accept="image/*"
                              />
                              {error.scan_certifica_cand && (
                                <div className="invalid-feedback">
                                  {error.scan_certifica_cand}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-4 align-self-end">
                            {certificat && (
                              <Image
                                src={certificat}
                                alt="Image"
                                width="60"
                                preview
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 d-flex align-item-stretch">
                    <div className="card w-100">
                      <div className="card-body">
                        <div className="row g-2">
                          <div className="col-lg-6">
                            <label className="form-label">Ville</label>
                            <input
                              className={
                                "form-control " +
                                (error.ville_cand && "is-invalid")
                              }
                              name="ville_cand"
                              value={formValues.ville_cand}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir ville"
                            />
                            {error.ville_cand ? (
                              <div className="invalid-feedback">
                                {error.ville_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">Région</label>
                            <Select
                              value={Reg}
                              options={regions}
                              onChange={(select) => {
                                setReg(select);
                                setFormValues({
                                  ...formValues,
                                  province_cand: select.value,
                                });
                              }}
                              placeholder="Selectionnez un région"
                            />
                            {error.province_cand ? (
                              <div className="text-danger">
                                {error.province_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">Pays</label>
                            <input
                              className={
                                "form-control " +
                                (error.pays_cand && "is-invalid")
                              }
                              name="pays_cand"
                              value={formValues.pays_cand}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir adresse de l'apprenant"
                            />
                            {error.pays_cand ? (
                              <div className="invalid-feedback">
                                {error.pays_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12 col-lg-8">
                            <label className="form-label">Adresse</label>
                            <input
                              className={
                                "form-control " +
                                (error.adresse_cand && "is-invalid")
                              }
                              name="adresse_cand"
                              value={formValues.adresse_cand}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir adresse de l'apprenant"
                            />
                            {error.adresse_cand ? (
                              <div className="invalid-feedback">
                                {error.adresse_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className=" col-12 col-lg-4">
                            <label className="form-label">Code postal</label>
                            <input
                              className={
                                "form-control " +
                                (error.cp_cand && "is-invalid")
                              }
                              name="cp_cand"
                              value={formValues.cp_cand}
                              onChange={handleChange}
                              type="number"
                              placeholder="son code postal"
                            />
                            {error.cp_cand ? (
                              <div className="invalid-feedback">
                                {error.cp_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="my-3">
                          <label className="form-label">Telephone (*)</label>
                          <InputMask
                            onKeyUp={(e) => {
                              console.log(e.target.value);
                              if (e.target.value === "") {
                                setError({
                                  ...error,
                                  tel_cand: ["le champs telephone est requis"],
                                });
                              } else if (e.target.value !== "") {
                                setError({ ...error, tel_cand: [] });
                              }
                            }}
                            onChange={handleChange}
                            mask="+261 39 99 999 99"
                            type="text"
                            className={
                              "form-control " +
                              (error.tel_cand?.length > 0 && " is-invalid")
                            }
                            value={formValues.tel_cand}
                            name="tel_cand"
                            placeholder="Telephone"
                          />

                          {error.tel_cand &&
                            error.tel_cand.map((element) => (
                              <p
                                key={element}
                                className="invalid-feedback p-0 m-1"
                              >
                                * {element}
                              </p>
                            ))}
                        </div>
                        <div className="row my-3">
                          <div className="col-lg-6">
                            <label className="form-label">
                              Catégorie de permis
                            </label>
                            <SelectCustom
                              options={options}
                              load={loadcateg}
                              choix={choix}
                              placeholder="choix catégorie"
                              setFormValues={setFormValues}
                              formValues={formValues}
                              setError={setError}
                              error={error}
                              name={"categ_permis"}
                            />

                            {error.categ_permis ? (
                              <div className="text-danger">
                                {error.categ_permis}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">
                              Date d'inscription de l'apprenant
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.date_insc_cand && "is-invalid")
                              }
                              name="date_insc_cand"
                              value={formValues.date_insc_cand}
                              onChange={handleChange}
                              type="date"
                              placeholder="Chosir date"
                            />
                            {error.date_insc_cand ? (
                              <div className="invalid-feedback">
                                {error.date_insc_cand}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {landing ? null : (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              disabled={load}
              onClick={() => (mode ? handleUpdate() : handleSubmit())}
            >
              {mode ? <>Modifier</> : <>Enregistrer</>}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}
