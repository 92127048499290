import {
    faBell,
    faBookOpenReader,
    faCommentSms,
    faHistory,
    faHome,
    faMessage,
    faRightToBracket,
    faUserGear,
    faUserShield,
  } from "@fortawesome/free-solid-svg-icons";
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import axios from "axios";

  import { toast } from "react-toastify";
  
import HeaderOrganisme from "./HeaderOrganisme";
import image from "../../../assets/image/imgtest/image";
import { Contact_API, LOGOUT, notificationCli_API } from "../../../service/config";
import isconnected from "../../../service/isconnected";
  
  export default function HeadORG() {
    const navigate =useNavigate()
    const [nombrmes, setnombrmes] = useState(0);

    const getAllNotification = async () => {
      try {
        axios.get(notificationCli_API,
          ).then((list) => {
          console.log(list);
  
          let i = 0;
          for (let index = 0; index < list.data.data.length; index++) {
            const element = list.data.data[index];
            if (element.is_read === false) {
              i++;
            }
          }
          setnombrmes(i);
          setTimeout(() => {
            getAllNotification()
          }, 60000);
        });
      } catch (error) {
        console.log(error);
      }
    };
  
    const Notifcomponent = ()=> {
      return  nombrmes > 0 ?   (<span
        className="avatar avatar-sm bg-success rounded-circle text-light"
        style={{ height: "15px", width: "15px" }}
      >
        {nombrmes}
      </span>) : null
    }
    useEffect(() => {
      getAllNotification();
      
    }, []);
  
    useEffect(() => {
      Notifcomponent();
    }, [nombrmes]);


    const logout = async () => {
      const dec = toast.loading("Déconnexion !");
      try {
        await axios
          .post(LOGOUT)
          .then((response) => {
            delete axios.defaults.headers["Authorization"];
            navigate("/login");
  
            toast.update(dec, {
              render: "Session déconnecter!",
              type: "success",
              isLoading: false,
              autoClose: 1000,
            });
            window.localStorage.removeItem("authToken");
          })
          .catch((error) => {
            console.log(error);
            toast.update(dec, {
              render: "Echec de déconnexion !",
              type: "error",
              isLoading: false,
              autoClose: 1000,
            });
          });
      } catch (error) {
        toast.update(dec, {
          render: "Echec de déconnexion !",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    };
    return (
      <div className="w-100">
        <div className="m-0 p-0 " style={{ zIndex: "88" }}>
          <nav
            id="nav-home"
            className="navbar navbar-expand-md bg-light  border border-bottom "
          >
            <div className="container-lg container-fluid">
              <Link to="/" className="navbar-brand me-5" href="/#">
                <img
                  src={image.mtm}
                  alt=""
                  width="35"
                  height="auto"
                  className="d-inline-block align-text-top"
                />{" "}
              </Link>
  
              <div className="navbar-nav ">
              <div
                    onClick={() => {
                      // setnotif(false);
                      navigate("/notifications");
                    }}
                    className="navbar-brand me-2"
                  >
                    <FontAwesomeIcon icon={faBell} color="#9DA9B9" />{" "}
                    {( nombrmes > 0) && (
                      <span
                        className="avatar avatar-sm bg-danger rounded-circle text-light"
                        style={{ height: "15px", width: "15px" }}
                      >
                        {nombrmes}
                      </span>
                    )}
                  </div>
                    <div className="dropdown p-2">
                      <a
                        href="/#"
                        className="nav-link d-flex lh-1 text-reset p-0"
                        data-bs-toggle="dropdown"
                        aria-label="Open user menu"
                        aria-expanded="false"
                      >
                        <span
                          className="avatar avatar-sm"
                          style={{ backgroundImage: "url(" + image.avatar + ")" }}
                        ></span>
                        <div className="ps-2 d-lg-block d-none">
                          <div className=""> {isconnected.user().nom_util}</div>
                          <div className="mt-1 small text-muted">
                          {isconnected.user().type_util == "Examinateurs" && isconnected.user().colonne_util == 'Admin' ? "Centre d'examen":isconnected.user().type_util }
                          </div>
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow animate slideIn">
                        <Link className="dropdown-item " to="/Account">
                          <FontAwesomeIcon
                            icon={faUserGear}
                            color="#9DA9B9"
                            className="pe-2"
                          />
                          Mon compte
                        </Link> 
                        <Link className="dropdown-item " to="/historique">
                          <FontAwesomeIcon
                            icon={faHistory}
                            color="#9DA9B9"
                            className="pe-2"
                          />
                          Historiques
                        </Link> 
                        <div
                         onClick={()=> logout()}
                          className="dropdown-item"
                          type="button"
                        >
                          <FontAwesomeIcon
                            icon={faRightToBracket}
                            color="red"
                            className="pe-2"
                          />
                          Deconnecter
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            
          </nav>
         <HeaderOrganisme />
        </div>
      </div>
    );
  }
  