import React, { useState } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { file_url } from "../../../../service/config";
import { Image } from "primereact/image";
import { regions } from "../../../../service/auth/UtilApi";
import SelectFinal from "../../../../outils/Select/SelectFinal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import SelectCustom from "../../../../outils/Select/SelectComponent";
import { Tag } from "primereact/tag";

export default function VerifyCandidature({ row }) {
  const [choix, setchoix] = useState([]);

  var text = "";

  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState([]);
  const [Reg, setReg] = useState({});
  const [categorie, setcategorie] = useState([]);
  const [landing, setlanding] = useState(true);
  const [loading, setloading] = useState(false);
  const abortController = new AbortController();
  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState(false);
  const [cin, setcin] = useState(null);
  const [cin_verso, setcin_verso] = useState(null);
  const [certificat, setcertificat] = useState(null);
  const [ito, setIto] = useState([]);
  const [loadcateg, setloadcateg] = useState(false);
  const [error, setError] = useState({});
  const [formValues, setFormValues] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    photo_cand: null,
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    adresse_cand: "",
    ville_cand: "",
    province_cand: "",
    cp_cand: "",
    active_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    id_org: "",
  });

  const [vide, setvide] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    cin_verso_cand: "",
    photo_cand: null,
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    adresse_cand: "",
    ville_cand: "",
    province_cand: "",
    cp_cand: "",
    active_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    id_org: "",
  });

  const handleShow = () => setShow(!show);

  const [img, setImage] = useState(null);

  const DetailsApprennant = (element) => {
    setlanding(false);
    setmode(true);
    setReg({ value: element.province_cand, label: element.province_cand });
    let categ = element?.categ_permis?.split(",");
    let valueCat = [];
    categ?.map((i) => i != "" && valueCat.push({ value: i, label: i }));
    setchoix(element.categ_permis);
    setIto(valueCat);
    const { photo_cand, scan_cin_can, scan_certifica_cand, ...formfiltrer } =
      element;
    setFormValues(formfiltrer);
    setcin(file_url + element.scan_cin_can);
    setcin_verso(file_url + element.cin_verso_cand);
    setcertificat(file_url + element.scan_certifica_cand);
    setImage(file_url + element.photo_cand);
    handleShow(!show);
  };

  return (
    <div>
      <div className="mx-3">
        <Button variant="secondary" onClick={() => DetailsApprennant(row)}>
          {" "}
          <FontAwesomeIcon className="me-2" icon={faEye} /> Voir
        </Button>
      </div>
      <Modal show={show} size="xl" onHide={handleShow}>
        <Modal.Header closeButton>
          <h3>Details candidature</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-2">
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <div className="">
                        <label className="form-label">PHOTO </label>
                      </div>
                    </div>
                    <div className="col-4 align-self-end">
                      {img && (
                        <Image src={img} alt="Image" width="150" preview />
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">Catégories a l'examen</label>
                    <div className="d-flex gap-1 border p-2 rounded-2">
                      {formValues?.categories?.map((el, i) => (
                        <Tag key={i}>{el.categ}</Tag>
                      ))}
                    </div>
                  </div>
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <label className="form-label">Nom</label>
                      <input
                        className={
                          "form-control " + (error.nom_cand && "is-invalid")
                        }
                        name="nom_cand"
                        value={formValues.nom_cand}
                        type="text"
                        placeholder="Saisir nom "
                      />
                      {error.nom_cand ? (
                        <div className="invalid-feedback">{error.nom_cand}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Prenom</label>
                      <input
                        className={
                          "form-control " + (error.prenom_cand && "is-invalid")
                        }
                        name="prenom_cand"
                        value={formValues.prenom_cand}
                        type="text"
                        placeholder="Saisir prenom"
                      />
                      {error.prenom_cand ? (
                        <div className="invalid-feedback">
                          {error.prenom_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">CIN</label>
                      <input
                        className={
                          "form-control " + (error.cin_cand && "is-invalid")
                        }
                        name="cin_cand"
                        value={formValues.cin_cand}
                        type="text"
                        placeholder="Saisir CIN"
                      />
                      {error.cin_cand ? (
                        <div className="invalid-feedback">{error.cin_cand}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Sexe</label>
                      <select
                        className={
                          "form-control " + (error.sexe_cand && "is-invalid")
                        }
                        name="sexe_cand"
                        value={
                          formValues.sexe_cand === true
                            ? "1"
                            : formValues.sexe_cand === false
                            ? "0"
                            : formValues.sexe_cand === ""
                            ? formValues.sexe_cand
                            : formValues.sexe_cand
                        }
                      >
                        <option>choisir sexe ...</option>
                        <option value={0}>Femme</option>
                        <option value={1}>Homme</option>
                      </select>
                      {error.sexe_cand ? (
                        <div className="invalid-feedback">
                          {error.sexe_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Date de naissance</label>
                      <input
                        className={
                          "form-control " + (error.naiss_cand && "is-invalid")
                        }
                        name="naiss_cand"
                        value={formValues.naiss_cand}
                        type="date"
                        placeholder="Chosir date"
                      />
                      {error.naiss_cand ? (
                        <div className="invalid-feedback">
                          {error.naiss_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Nationalité</label>
                      <input
                        className={
                          "form-control " +
                          (error.nationalite_cand && "is-invalid")
                        }
                        name="nationalite_cand"
                        value={formValues.nationalite_cand}
                        type="text"
                        placeholder="Saisir son nationalité"
                      />
                      {error.nationalite_cand ? (
                        <div className="invalid-feedback">
                          {error.nationalite_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">Email</label>
                    <input
                      className={
                        "form-control " + (error.email_cand && "is-invalid")
                      }
                      name="email_cand"
                      value={formValues.email_cand}
                      type="email"
                      placeholder="Saisir email"
                    />
                    {error.email_cand ? (
                      <div className="invalid-feedback">{error.email_cand}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3 g-2 row align-items-center">
                    <div className="col-12">Scan CIN de l'apprenant:</div>
                    <div className="col-6">
                      <div className="">
                        <label className="form-label">recto : </label>

                        {error.scan_cin_can && (
                          <div className="invalid-feedback">
                            {error.scan_cin_can}
                          </div>
                        )}
                        {cin && (
                          <Image src={cin} alt="Image" width="100" preview />
                        )}
                      </div>
                    </div>
                    <div className="col-6 ">
                      <div className="">
                        <label className="form-label">verso : </label>

                        {cin_verso && (
                          <Image
                            src={cin_verso}
                            alt="Image"
                            width="60"
                            preview
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="my-3 row align-items-center">
                    <div className="col-12">
                      <div className="">
                        <label className="form-label">
                          Scan certificat de résidence de l'apprenant{" "}
                        </label>

                        {error.scan_certifica_cand && (
                          <div className="invalid-feedback">
                            {error.scan_certifica_cand}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 align-self-end">
                      {certificat && (
                        <Image
                          src={certificat}
                          alt="Image"
                          width="100"
                          preview
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <label className="form-label">Ville</label>
                      <input
                        className={
                          "form-control " + (error.ville_cand && "is-invalid")
                        }
                        name="ville_cand"
                        value={formValues.ville_cand}
                        type="text"
                        placeholder="Saisir ville"
                      />
                      {error.ville_cand ? (
                        <div className="invalid-feedback">
                          {error.ville_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Région</label>
                      <Select
                        value={Reg}
                        options={regions}
                        placeholder="Selectionnez un région"
                      />
                      {error.province_cand ? (
                        <div className="text-danger">{error.province_cand}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12">
                      <label className="form-label">Pays</label>
                      <input
                        className={
                          "form-control " + (error.pays_cand && "is-invalid")
                        }
                        name="pays_cand"
                        value={formValues.pays_cand}
                        type="text"
                        placeholder="Saisir adresse de l'apprenant"
                      />
                      {error.pays_cand ? (
                        <div className="invalid-feedback">
                          {error.pays_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12 col-lg-8">
                      <label className="form-label">Adresse</label>
                      <input
                        className={
                          "form-control " + (error.adresse_cand && "is-invalid")
                        }
                        name="adresse_cand"
                        value={formValues.adresse_cand}
                        type="text"
                        placeholder="Saisir adresse de l'apprenant"
                      />
                      {error.adresse_cand ? (
                        <div className="invalid-feedback">
                          {error.adresse_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className=" col-12 col-lg-4">
                      <label className="form-label">Code postal</label>
                      <input
                        className={
                          "form-control " + (error.cp_cand && "is-invalid")
                        }
                        name="cp_cand"
                        value={formValues.cp_cand}
                        type="number"
                        placeholder="son code postal"
                      />
                      {error.cp_cand ? (
                        <div className="invalid-feedback">{error.cp_cand}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">Telephone (*)</label>
                    <InputMask
                      onKeyUp={(e) => {
                        console.log(e.target.value);
                        if (e.target.value === "") {
                          setError({
                            ...error,
                            tel_cand: ["le champs telephone est requis"],
                          });
                        } else if (e.target.value !== "") {
                          setError({ ...error, tel_cand: [] });
                        }
                      }}
                      mask="+261 39 99 999 99"
                      type="text"
                      className={
                        "form-control " +
                        (error.tel_cand?.length > 0 && " is-invalid")
                      }
                      value={formValues.tel_cand}
                      name="tel_cand"
                      placeholder="Telephone"
                    />

                    {error.tel_cand &&
                      error.tel_cand.map((element) => (
                        <p key={element} className="invalid-feedback p-0 m-1">
                          * {element}
                        </p>
                      ))}
                  </div>
                  <div className="row my-3 g-2">
                    <div className="col-lg-12">
                      <label className="form-label">
                        Catégorie de permis à l'inscription
                      </label>
                      <SelectFinal
                        options={ito}
                        load={loadcateg}
                        choix={choix}
                        max={10}
                        label={"label"}
                        value={"label"}
                        placeholder="choix catégorie"
                        setFormValues={setFormValues}
                        formValues={formValues}
                        setError={setError}
                        error={error}
                        name={"categ_permis"}
                      />

                      {error.categ_permis ? (
                        <div className="text-danger">{error.categ_permis}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">
                        Date d'inscription de l'apprenant
                      </label>
                      <input
                        className={
                          "form-control " +
                          (error.date_insc_cand && "is-invalid")
                        }
                        name="date_insc_cand"
                        value={formValues.date_insc_cand}
                        type="date"
                        placeholder="Chosir date"
                      />
                      {error.date_insc_cand ? (
                        <div className="invalid-feedback">
                          {error.date_insc_cand}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
