import jwtDecode from "jwt-decode";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import HeadORG from "../../components/Admin/Organisme/HeadORG";
import Footer from "../../components/Footer/Footer";
import Head from "../../components/header/Head";
import Header from "../../components/header/Header";
import AuthContext from "../context/AuthContext";
import isconnected from "../isconnected";
import ScrollToTopOnMount from "../remonter";
import { useSelector } from "react-redux";
import AccueilORG from "../../components/Admin/Organisme/AccueilORG";
import BoiteNotification from "../../components/Notification/BoiteNotification";
import GererSens from "../../components/Admin/Sensibilisation/GererSens";
import Details_sens from "../../components/Accueil/section/Details_sens";
import NotFound from "../../components/404/NotFound";
import Account from "../../components/Compte/Grand Public/Account";

const PrivateRoute = () => {
  let typeOrg = [
    "Auto-écoles",
    "CIM",
    "Examinateurs",
    "ATT",
    "MTM",
    "PTF",
    "Autre",
  ];
  const type_user = isconnected.user();
  return type_user.type_util === "SuperAdmin" ||
    type_user.type_util === "Grand public" ||
    typeOrg.includes(type_user.type_util) ? (
    <>
      <Outlet />{" "}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

const AdminRoute = (props) => {
  const type_user = isconnected.user();
  console.log(type_user.type_util);
  return type_user.type_util === "SuperAdmin" ? (
    <>
      <Head setactif={props.setactif} />
      <div
        style={{ paddingTop: "3rem" }}
        data-bs-toggle="collapse"
        data-bs-target=".navbar-collapse.show"
      >
        <Outlet />
      </div>{" "}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

const Public = () => {
  const type_user = isconnected.user();
  console.log(type_user.type_util);
  return type_user.type_util === "Grand Public" ||
    type_user.type_util === "SuperAdmin" ? (
    <>
      <Outlet />{" "}
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

const OrganismeRoute = () => {
  let typeOrg = [
    "Auto-écoles",
    "CIM",
    "Examinateurs",
    "ATT",
    "MTM",
    "PTF",
    "Autre",
  ];

  const dataRedux = useSelector((state) => state.compte);
  const type_user = isconnected.user(); // Récupère l'utilisateur connecté
  const isAccountDeactivated = dataRedux?.active_org === "Desactiver"; // Vérifie si le compte est désactivé

  if (isAccountDeactivated) {
    // Autoriser uniquement les routes de notification et accueil
    return (
      <>
        <HeadORG />
        <div
          className=""
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <ScrollToTopOnMount />
          <Routes>
            <Route path="/gerer_message_all" element={<GererSens />} />
            <Route
              path="/détails sensibilisation/:id"
              element={<Details_sens />}
            />
            <Route path="/Account" element={<Account />} />
            <Route path="/notifications" element={<BoiteNotification />} />
            <Route path="/" element={<AccueilORG />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </>
    );
  }

  return typeOrg.includes(type_user.type_util) ? (
    <>
      <HeadORG />
      <div
        className=""
        data-bs-toggle="collapse"
        data-bs-target=".navbar-collapse.show"
      >
        <ScrollToTopOnMount />
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default { PrivateRoute, AdminRoute, OrganismeRoute };
