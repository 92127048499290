import {
  faAdd,
  faCheckCircle,
  faCheckSquare,
  faEdit,
  faEraser,
  faFilter,
  faHouse,
  faRotate,
  faTrash,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Modal,
  FormControl,
  Form,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { affecttheorie_API, candidat_API } from "../../../../../service/config";
import CardHover from "../../../../../outils/HoverCard/CardHover";
import ListCandidatsPrint from "../../../../../service/Print/ListCandidatsPrint";
import moment from "moment";
import { Tag } from "primereact/tag";
import DetailsCand from "../../../Organisme/DetailsCand/DetailsCand";



export default function CandEnvoyer() {
  const abortController = new AbortController();
  const columns = [
    // {
    //   name: "ID",
    //   selector: (row) => row.id_cand,
    //   sortable: true,
    // },
    {
        width: "23%",
        name: "Nom et prenom",
        selector: (row) => (
      <CardHover candidat={row}/>
        ),
        sortable: true,
      },
    {
      name: "Permis",
      selector: (row) => row.categ_permis,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.tel_cand,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_cand,
      sortable: true,
    },
    {
      name: "Date d'inscription",
      selector: (row) => row.date_insc_cand,
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse_cand,
      sortable: true,
    },
    {
      name: "type",
      selector: (row) => row.type_cand,
      sortable: true,
    },

    /*{
        width: "105px",
        name: "statut",
        selector: (row) => (
          <span className="badge bg-cyan">{row.active_cand}</span>
        ),
        sortable: true,
      },*/
    // ,
    // {
    //   width: "105px",
    //   cell: (row) => (
    //     <div className="">
    //       <button
    //         className="btn btn-primary"
    //         onClick={() => {
    //           // alert(JSON.stringify(row));
    //           setmode(true);
    //           setFormValues(row);
    //           setShow(true);
    //           setImage(`${file_url}/${row.photo_cand}`);
    //           setcertificat(`${file_url}/${row.scan_certifica_cand}`);
    //           setcin(`${file_url}/${row.scan_cin_can}`);
    //         }}
    //       >
    //         <FontAwesomeIcon icon={faEdit} />
    //       </button>
    //     </div>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];
  const columns_valid = [
    {
        width: "23%",
        name: "Nom et prenom",
        selector: (row) => (
      <CardHover candidat={row}/>
        ),
        sortable: true,
      },
    {
      name: "Adresse",
      selector: (row) => row.adresse_cand,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.tel_cand,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_cand,
      sortable: true,
    },
    {
      name: "type",
      selector: (row) => row.type_cand,
      sortable: true,
    },
    /*,
      {
        width: "105px",
        name: "statut",
        selector: (row) => (
          <span className="badge bg-cyan">{row.active_cand}</span>
        ),
        sortable: true,
      }*/ {
      name: "action",
      cell: (row) => (
        <div className="">
          <button
            className="btn btn-primary"
            onClick={() => {
              let mis = candidatSelect.filter(
                (el) => el.id_cand != row.id_cand
              );
              setcandidatSelect(mis);
            }}
          >
            <FontAwesomeIcon icon={faEraser} className="me-2" /> retirer
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const confCand = [
    {
         name: "Détails",
         wrap: true,
         
         selector: (row) => <DetailsCand row={row}/>,
         sortable: true,
       },
       {
         name: "Catégories examen",
         selector: (row) => (
           <div className="py-3 d-flex gap-1">
             {row?.categories?.map((el, i) => (
               <Tag key={i}>{el.categ}</Tag>
             ))}
           </div>
         ),
         sortable: true,
       },
       {
         name: "numéro permis",
         width: "170px",
         selector: (row) => "non fourni",
         sortable: true,
       },
       {
         name: "Visa CIM",
         width: "170px",
         selector: (row) => row.visa_cim || "non fourni",
         sortable: true,
       },
       {
         name: "Nom et prenom",
         width: "170px",
         selector: (row) => (
           <>
             {row.nom_cand} {row.prenom_cand}
           </>
         ),
         sortable: true,
       },
       {
         name: "Date d'inscription",
         selector: (row) => moment(row.date_insc_cand).locale("fr").format("LL"),
         sortable: true,
         width: "170px",
       },
       {
         name: "Date d'envoi",
         width: "200px",
         cell: (row) => moment(row.date_status_cand).locale("fr").format("LL"),
         selector: (row) => row.date_status_cand,
         sortable: true,
       },
       {
         name: "nombre de session",
         width: "200px",
         selector: (row) => 0,
         sortable: true,
       },
       {
         name: "derogation",
         width: "200px",
         selector: (row) => "Aucun",
         sortable: true,
       },
       {
         name: "Adresse",
         width: "170px",
         selector: (row) => row.adresse_cand,
         sortable: true,
       },
       {
         name: "Ville",
         width: "200px",
         wrap: true,
         selector: (row) => row.ville_cand,
         sortable: true,
       },
       {
         width: "200px",
         name: "Region",
         wrap: true,
         selector: (row) => row.province_cand,
         sortable: true,
       },
       {
         name: "statut",
         minWidth: "280px",
         selector: (row) => (
           <span className="badge bg-cyan p-2">{row.status_examen_cand}</span>
         ),
         sortable: true,
       },
  ];
  const [data, setdata] = useState([
    {
      nom: "Razafindrakoto",
      prenom: "Tiana",
      adresse: "2 Rue des Baobabs, Antsirabe",
      telephone: "+261 34 98 765 43",
      email: "tiana.razafindrakoto@example.com",
      apprenant: "non valide",
      status: "repassage",
      datainsc: "2022-01-03",
    },
    {
      nom: "Rakoto",
      prenom: "Jean",
      adresse: "12 Rue des Fleurs, Antananarivo",
      telephone: "+261 32 12 345 67",
      email: "jean.rakoto@example.com",
      apprenant: "valide",
      status: "passant",
      datainsc: "2022-02-05",
    },
    {
      nom: "Raharisoa",
      prenom: "Tina",
      adresse: "4 Rue des Coquelicots, Antsiranana",
      telephone: "+261 34 23 456 78",
      email: "tina.raharisoa@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2021-12-31",
    },
    {
      nom: "Andrianasolo",
      prenom: "Luc",
      adresse: "1 Rue des Lilas, Antananarivo",
      telephone: "+261 32 87 654 32",
      email: "luc.andrianasolo@example.com",
      apprenant: "non valide",
      status: "repassage",
      datainsc: "2022-01-01",
    },
    {
      nom: "Randriamanantena",
      prenom: "Sandra",
      adresse: "8 Rue des Roses, Fianarantsoa",
      telephone: "+261 34 56 789 01",
      email: "sandra.randriamanantena@example.com",
      apprenant: "valide",
      status: "passant",
      datainsc: "2022-01-23",
    },
    {
      nom: "Ratovonirina",
      prenom: "Feno",
      adresse: "7 Rue de la Mer, Toamasina",
      telephone: "+261 33 45 678 90",
      email: "feno.ratovonirina@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2022-02-01",
    },
    {
      nom: "Ratsimba",
      prenom: "Fara",
      adresse: "5 Rue des Narcisses, Antsirabe",
      telephone: "+261 32 34 567 89",
      email: "fara.ratsimba@example.com",
      apprenant: "valide",
      status: "repassage",
      datainsc: "2022-01-01",
    },
  ]);

  const [dataOrg, setdataorg] = useState([]);
  const [show, setShow] = useState(false);
  const [ModalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [textfiltre, settextfiltre] = useState("");
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const [showvalid, setshowvalid] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [candidatSelect, setcandidatSelect] = useState([]);
  const [categorie, setcategorie] = useState([]);
  const [load, setLoad] = useState(false);
  const [rotation, setRotation] = useState(0);
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };
  const [formValues, setFormValues] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    photo_cand: "",
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    adresse_cand: "",
    ville_cand: "",
    active_cand: "",
    province_cand: "",
    cp_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    date_insc_cand: "",
    scan_certifica_cand: "",
    scan_cin_can: "",
  });

  const [vide, setvide] = useState({
    nom_cand: "",
    prenom_cand: "",
    cin_cand: "",
    photo_cand: null,
    sexe_cand: "",
    naiss_cand: "",
    nationalite_cand: "",
    fonction_memb: "",
    adresse_cand: "",
    ville_cand: "",
    active_cand: "",
    province_cand: "",
    cp_cand: "",
    pays_cand: "",
    tel_cand: "",
    email_cand: "",
    categ_permis: "",
    date_insc_cand: "",
  });

  const ModalShowclose = () => setModalShow(!ModalShow);
  const handleRotate = () => {
    setRotation(rotation + 90);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const handleValidation = () => setshowvalid(!showvalid);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [imagefull, setimagefull] = useState(null);
  const [cin, setcin] = useState(null);
  const [certificat, setcertificat] = useState(null);

  const getCandidats = () => {
    setLoad(true);
    axios
      .get(candidat_API + `?active_apprenant=true&type_cand=Apprenants`, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log("first");
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const LesCandidatEnvoyer = () => {
    setLoad(true);
    axios
      .get(affecttheorie_API, {
        signal: abortController.signal,
      })
      .then((reponse) => {
        console.log(reponse.data);
        setdata(reponse.data.data);
        setLoad(false);
      })
      .catch((error) => {
        if (error.response.status == 400) {
          toast.error(error.response.data.id_cand[0]);
        }
        setLoad(false);
      });
  };
  const datafiltrer = data.filter((item) => {
    return (
      (item.id_cand && item.id_cand === textfiltre) ||
      (item.nom_cand &&
        item.nom_cand.toLowerCase().includes(textfiltre.toLowerCase()))
    );
  });
  const handleSearch = (e) => {
    settextfiltre(e.target.value);
  };
  useEffect(() => {
    LesCandidatEnvoyer();

    settextfiltre("");
  
  }, []);
  return (
    <div className="container-lg container-fluid">
  

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Filtre</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <DataTable
              className="custom-table"
              title={<p className="fs-4">Liste des candidats</p>}
              columns={columns}
              data={data.filter((el) => el.active_cand != "Activer")}
              selectableRows
              clearSelectedRows={toggledClearRows}
              onSelectedRowsChange={(rows) => {
                setSelectedRows(rows.selectedRows.map((row) => row.id_cand));
                setcandidatSelect(rows.selectedRows.map((row) => row));
              }}
              progressPending={load}
              selectedRow={selectedRows}
              contextActions={
                <div className="d-flex">
                  <Button
                    className="me-2"
                    variant="secondary"
                    onClick={() => {
                      settoggledClearRows(!toggledClearRows);
                    }}
                  >
                    <FontAwesomeIcon icon={faUndoAlt} className="" />
                  </Button>
                  <button
                    className="btn btn-cyan me-2"
                    onClick={() => {
                      handleValidation();
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                </div>
              }
              progressComponent={
                <div className="w-100 d-flex justify-content-center py-5">
                  <TailSpin
                    height="80"
                    width="80"
                    color="#22577A"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              }
              contextMessage={{
                singular: "élement",
                plural: "élements",
                message: "sélectionnées",
              }}
              pagination
              paginationPerPage={5}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page",
              }}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant="primary"
              disabled={load}
              onClick={() => (mode ? handleUpdate() : handleSubmit())}
            >
              {mode ? <>Modifier</> : <>Enregistrer</>}
            </Button>
          </Modal.Footer> */}
      </Modal>
      <Modal
        show={showvalid}
      
        onHide={() => {
          handleValidation();
          handleClearRows();
        }}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Les apprenants qui sont sélectionnées pour être candidats </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <div className="col-12 d-flex align-item-stretch">
                <div className="w-100 my-2 px-3">
                  <p>Epreuve précedemment raté pour ceux qui fait le repassage</p>
                  <select
                    className={"form-control " + (error.nom_org && "is-invalid")}
                    name="nom_org"
                  >
                    <option value="">Epreuve du 10-10-22</option>
                    <option value="">Epreuve du 10-12-22</option>
                    <option value="">Epreuve du 9-03-22</option>
                  </select>
                  {error.nom_org ? (
                    <div className="invalid-feedback">{error.nom_org}</div>
                  ) : (
                    <></>
                  )}
                </div>
              </div> */}
            <div className="col-12">
              <DataTable
                className="custom-table"
                title={
                  <p className="fs-2">Liste des candidats sélectionnées</p>
                }
                columns={columns_valid}
                data={candidatSelect.sort(
                  (a, b) =>
                    new Date(b.date_status_cand) - new Date(a.date_status_cand)
                )}
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleValidation();
              handleClearRows();
            }}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={ModalShow} onHide={ModalShowclose} size="md">
        <Modal.Body>
          <img
            src={imagefull}
            onError={ModalShowclose}
            alt={"version grandeur nature"}
            style={{
              width: "100%",
              height: "100%",
              transform: `rotate(${rotation}deg)`,
            }}
          />
          <div className="w-100">
            <button
              type="button"
              onClick={handleRotate}
              className="btn btn-primary"
            >
              Tourner <FontAwesomeIcon className="ms-2" icon={faRotate} />
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="">
        <div className="row g-0">
          <div className="col-12 d-flex flex-column">
            <div className="">
              <DataTable
                className="custom-table"
                title={
                 <div className="d-flex justify-content-between align-items-center">
                   <p className="h3">{"Liste des candidats envoyée à l’ATT"}</p>
                  <ListCandidatsPrint titre={"Liste des candidats envoyée à l’ATT"} candidats={datafiltrer}/>
                 </div>
                }
                columns={confCand}
                data={datafiltrer.sort(
                  (a, b) =>
                    new Date(b.date_status_cand) - new Date(a.date_status_cand)
                )}
                selectableRows={false}
                clearSelectedRows={toggledClearRows}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows.selectedRows.map((row) => row.id_cand));
                  setcandidatSelect(rows.selectedRows.map((row) => row));
                }}
                progressPending={load}
                selectedRow={selectedRows}
                contextActions={
                  <div className="d-flex">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={() => {
                        settoggledClearRows(!toggledClearRows);
                      }}
                    >
                      <FontAwesomeIcon icon={faUndoAlt} className="" />
                    </Button>
                    <button
                      className="btn btn-cyan me-2"
                      onClick={() => {
                        handleValidation();
                      }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                      voir la liste des selectionné
                    </button>
                  </div>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
